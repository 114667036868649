import Hls from 'hls.js';

export default class {
  #hlsInstance = null;
  #video = null;
  #src = null;
  #isNativeSupport = false;
  #endVideoCallback = () => {};

  bindVideo(el, src) {
    this.#video = el;
    this.#src = src;

    return this.#src;
  }

  canPlay() {
    if (!this.#video) {
      return false;
    }

    if (this.#video.canPlayType('application/vnd.apple.mpegurl')) {
      this.#isNativeSupport = true;
      return true;
    }

    const isSupported = Hls.isSupported();

    if (isSupported) {
      this.#hlsInstance = new Hls({
        liveDurationInfinity: true,
      });
    }

    return isSupported;
  }

  setVideoSrc(maxLevel = false) {
    if (this.#isNativeSupport) {
      this.#video.src = this.#src;
    } else {
      this.#hlsInstance.loadSource(this.#src);
      this.#hlsInstance.attachMedia(this.#video);

      /* this.#hlsInstance.on(Hls.Events.BUFFER_CREATED, () => {
        console.log('this.#video.duration', this.#video.duration);
        this.#video.currentTime = this.#video.duration;
      });
      */
      this.#hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
        // this.#video.currentTime = 100000;

        if (maxLevel) {
          this.#hlsInstance.nextLevel = this.#hlsInstance.maxAutoLevel;
        } else {
          this.#hlsInstance.currentLevel = 1;
        }
      });
    }
  }

  async play(maxLevel = false) {
    this.setVideoSrc(maxLevel);

    return new Promise((resolve) => {
      try {
        if (this.#isNativeSupport) {
          this.localPlay(resolve);
        } else {
          this.#hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
            try {
              this.localPlay(resolve);
            } catch (e) {
              resolve(false);
            }
          });
        }
      } catch (e) {
        resolve(false);
      }
    });
  }

  async localPlay(resolve) {
    await this.#video.play();
    this.#bindEvents();
    resolve(true);
  }

  clear() {
    this.#isNativeSupport = false;

    if (this.#video) {
      this.#video.src = null;
    }

    if (this.#hlsInstance) {
      this.#hlsInstance.stopLoad();
      this.#hlsInstance.detachMedia();
    }

    this.#hlsInstance &&
      this.#hlsInstance.media &&
      this.#hlsInstance.media.pause();
    this.#hlsInstance && this.#hlsInstance.detachMedia();

    this.#video = null;
    this.#endVideoCallback = () => {};

    this.#hlsInstance = null;
  }

  onVideoEnded(callback) {
    this.#endVideoCallback = callback;
  }

  #bindEvents() {
    if (!this.#video) {
      return;
    }

    this.#video.onended = () => {
      if (this.#hlsInstance) {
        this.#hlsInstance.stopLoad();
        this.#hlsInstance.detachMedia();
      }

      this.#endVideoCallback();
    };
  }
}
