import { amsClient } from '@/service/ams';

// State
const state = () => ({
    currentSearchLink: '',
		isLoading: false,
		allStoresObj: null,
    actualStoresArray: [],
});

// Getters
const getters = {
  getCurrentSearchLink: state => {
    return state.currentSearchLink;
  },
	getIsLoading: state => {
		return state.isLoading;
	},
  getActualStoresArray: state => {
		return state.actualStoresArray;
	},
	getAllStores(state) {
    return state.allStoresObj?.items;
  },
  getStoresArr: state => {
    if(state.allStoresObj) {
      const storesObj = state.allStoresObj.items.find(i => i.title === 'Stores');

      return storesObj?.stores ? storesObj.stores : [];
    };

    return [];
  },
};

// Actions
const actions = {
  setCurrentSearchLink({commit}, payload) {
		commit('setCurrentSearchLink', payload);
	},
  setActualStoresArray({commit}, payload) {
    commit('setActualStoresArray', payload)
  },
	async fetchAllStoresObj({commit}, payload) {
		commit('setIsLoading', true);

    try {
      const stores = await amsClient.callAms(payload);

			commit('setAllStoresObj', stores);
      commit('setIsLoading', false);

      return stores;
		}catch(error) { 
			console.error('Fetching all stores JSON failed' ,error);
		}
	},
};

// mutations
const mutations = {
  setCurrentSearchLink(state, payload) {
    state.currentSearchLink = payload;
  },
	setIsLoading(state, payload) {
		state.isLoading = payload;
	},
  setAllStoresObj(state, payload) {
    state.allStoresObj = payload;
  },
  setActualStoresArray(state, payload) {
    state.actualStoresArray = payload;
  },
};

export default {
  state,
  namespaced: true,
  getters,
  actions,
  mutations,
};