/**
 * Module for normalisation image.<br/>
 * Basicaly used as filter function for Vue2.0, but can be used as self.
 * @module
 *
 * @requires lodash/memoize
 * @requires lodash/isEmpty
 * @requires module:contextService
 * @requires module:device
 */
import { memoize, isEmpty } from 'lodash';
import device from 'src/service/device-service';

/**
 * Normalise Image name for posters on portal.
 *
 * A part of link <var>original.</var> will be replaced one from resolution:
 * * <var>ED</var> - ED images with the lowest quality and resolution 267x150px)
 * * <var>SD</var> - images with resolution 891x558 px
 * * <var>HD</var> - images with resolution 1133x637 px
 *
 * The replacing resolution will be in cases:
 * * Link is aviable
 * * Link is from defined domain (Currently.:'protv.ro') or from 'vidnt.com'.
 * * Link have a <var>original.</var> in name.
 *
 *  Domain from {0-6} can be repllaced in cases:
 * * Link is aviable
 * * Link is from domain.
 * * Config param for multidomain is defined
 *
 * @param {String} imgUrl a original link to poster
 * @param {('image'|'thumb')} type Type for image processing. <br/>'image' used for show big images.<br/>'thumb' used for small images.
 * @param {String} [imgDesktop] type used pernamently for desktop resolutions
 * @param {String} [imgMobile] type used pernamently for mobile resolutions
 *
 * @return {String} Normalised link or the same link if it is unaviable or have <code>.cust.</code> in name.
 */

// TODO: optimisation config loading
let normaliseImgFn = function (imgUrl, type, imgDesktop, imgMobile) {

	let multidomainDefault = { // todo: need to use from conf
		// 'avod.': [
		// 	'avod.',
		// 	'avod1.',
		// 	'avod2.',
		// 	'avod3.',
		// 	'avod4.',
		// 	'avod5.',
		// 	'avod6.',
		// ],
	};
	let multidomain = multidomainDefault;
	let imageRes = 'HD';
	let thumbRes = 'SD';
	let mobileRes = 'ED';


	if (!imgUrl || typeof imgUrl !== 'string') {
		return imgUrl;
	}
	// if (imgUrl.indexOf('.cust.') !== -1) {
	// 	return imgUrl;
	// }
	// if (imgUrl.indexOf('original.') !== -1 && (imgUrl.indexOf('vidnt.com') !== -1)) {
	if (imgUrl.includes('original.')) {
		if (type === 'thumb') {
			if (device.isMobileSize() || device.isAnyMobile()) {
				imgUrl = imgUrl.replace('original.', (imgMobile || mobileRes) + '.');
			} else {
				imgUrl = imgUrl.replace('original.', (imgDesktop || thumbRes) + '.');
			}
		} else if (type === 'image') {
			if (device.isMobileSize() || device.isAnyMobile()) {
				imgUrl = imgUrl.replace('original.', (imgMobile || thumbRes) + '.');
			} else {
				imgUrl = imgUrl.replace('original.', (imgDesktop || imageRes) + '.');
			}
		}
	}

	if (multidomain && !isEmpty(multidomain)) {
		imgUrl = multiDomainNormalisation(imgUrl, multidomain);
	}

	return imgUrl;
};

/**
 *  Couinter for current video
 */
let counter = -1;

/**
 *  Functions to retutn memoised domain for current imame
 */
let multiDomainNormalisation = memoize(function (imgUrl, multidomains) {
	let domains = Object.keys(multidomains);
	let domain = null;
	let image = imgUrl;

	let shouldReplace = domains.some(element => {
		domain = element;
		return image.indexOf(element) > -1;
	});

	if (!(shouldReplace || image.indexOf(domain) > -1)) {
		return image;
	}

	let variants = multidomains[domain];
	let len = variants.length;
	let rex = new RegExp(domain);

	counter = counter > -1 && counter < len - 1 ? counter + 1 : 0;
	image = image.replace(rex, variants[counter]);

	return image;
});

export default normaliseImgFn;
