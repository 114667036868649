import Vue from 'vue';
import TrimText from './trimText';
// import TrimTextHeight from './trimTextHeigh';
import BgImageJs from './backgroundImageJS';
import HoverJS from './hoverJS';
import OutsideClick from './OutsideClick';
import DragScrollFunction from './drag-scroll-fn';
import ResizeFunction from './resize';

export let hoverJs = Vue.directive('hoverJs', HoverJS);
export let trimmText = Vue.directive('ellipsisText', TrimText);
// export let trimmTextHeight = Vue.directive('trimText', TrimTextHeight)
export let bgImageJs = Vue.directive('bgImageJs', BgImageJs);
export let outsideClick = Vue.directive('outsideClick', OutsideClick);
export let dragScrollFn = Vue.directive('dragScrollFn', DragScrollFunction);
export let resizeFn = Vue.directive('resizeElement', ResizeFunction);

export default {
  hoverJs: hoverJs,
  ellipsisText: trimmText,
  bgImageJs: bgImageJs,
  resizeElement: resizeFn,
  // trimText: trimmTextHeight,
  outsideClick: OutsideClick,
  dragScrollFn: DragScrollFunction,
}
