<template>
  <nav v-if="removeHeader" class="toolbar" :class="hide">
    <div class="toolbar-container">
      <router-link to="/" class="logo" @click.native="closeAuthPopup()">
        <img :src="appLogo()" :alt="appTitle()" />
      </router-link>

      <button
        v-if="!$isStandaloneStore"
        id="catalog_btn"
        class="catalog-btn"
        @click="toggleCatalog"
      >
        <img
          :src="
            showCatalog
              ? require('src/assets/icons/catalog_close.svg')
              : require('src/assets/icons/categories_icon.svg')
          "
          alt="Catalog"
        />
        Shop Now
      </button>

      <nav-links @onLogin="onLogin" @logout="logout" />

      <header-search-block
        v-if="!$isStandaloneStore"
        @closeSearchProductPopup="closeSearchProductPopup"
      />
      <div v-else class="spacer" />

      <auth-actions
        :is-conf-loaded="isConfLoaded"
        @showAuthPopup="showAuthPopup()"
        @logout="logout()"
      />
      <cart />
    </div>
  </nav>
</template>

<script>
  import HeaderSearchBlock from 'src/components/search-block/SearchComponent.vue';
  import Auth from 'src/service/authService';
  import Config from 'src/service/config';
  import AuthBlock from 'src/components/auth-block/auth-block';
  import Cart from 'src/components/header/cart/Cart';
  import NavLinks from 'src/components/header/nav-links/NavLinks';
  import AuthActions from 'src/components/header/auth-actions/auth-actions';
  import device from 'src/service/device-service';
  import { formatDate } from 'src/filters';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import { activateFeature, isActiveFeature } from '@/plugins/feature-toggle';
  import WishlistService from '@/store/wishlist';

  export default {
    name: 'AppHeader',
    components: {
      AuthBlock,
      HeaderSearchBlock,
      Cart,
      NavLinks,
      AuthActions,
    },
    mixins: [AuthVariableMixin],
    props: {
      activePopup: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isActiveSidebar: false,
        isConfLoaded: false,
        isDesktop: true,
        isNewInboxMessages: false,
        isMainPage: false,
        showCatalog: false,
        username: Auth.get('user.username'),
        lastScroll: 0,
        hide: '',
        removeHeader: true,
        toggleStatus: false,
      };
    },
    computed: {
      profileTitle() {
        return this.$i18n.t('HEADER.PROFILE');
      },
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      isMiniatureFrame() {
        return this.screenType === 'miniature';
      },
    },
    watch: {
      $route(to, from) {
        this.isMainPage = to.fullPath === '/';
        if (to.query.onlyregister && !isActiveFeature('webview_feature')) {
          this.webviewRegister();
        } else if (
          !!to.query.onlyrestore &&
          !isActiveFeature('webview_feature')
        ) {
          this.webviewRestorePass();
        }
        this.$bus.$emit('ToggleMobileAuthActions', false);
        this.$bus.$emit('ToggleNavLinks', false);
      },
      isFullAuth: function () {
        this.username = Auth.get('user.username');
      },
      activePopup() {
        if (this.activePopup) {
          this.hide = '';
        }
      },
    },
    created() {
      this.isMainPage = this.$route.fullPath === '/';

      Config.loaded(() => {
        this.isConfLoaded = true;
        this.isDesktop = !device.isAnyMobile();
      });
    },
    mounted() {
      this.$bus.$on('toggleHeaderVisibility', ({ isHide }) => {
        this.removeHeader = isHide;
      });
      document.addEventListener('scroll', this.newScrollPosition);
      this.$bus.$on('ToggleCatalog', (data) => {
        this.showCatalog = data;
      });

      this.$bus.$on(
        'ScrollPositionToggleStatus',
        (toggleStatus) => (this.toggleStatus = toggleStatus),
      );

      if (
        this.$route.query.onlyregister &&
        !isActiveFeature('webview_feature')
      ) {
        this.$nextTick(() => {
          this.webviewRegister();
        });
      } else if (
        this.$route.query.onlyrestore &&
        !isActiveFeature('webview_feature')
      ) {
        this.$nextTick(() => {
          this.webviewRestorePass();
        });
      }
      this.$bus.$on('headerHide', () => {
        this.hide = '';
      });

      const timeout = setTimeout(() => {
        if (this.$route.query.login && !this.isFullAuth) {
          this.showAuthPopup();
        }

        if (!this.isFullAuth && this.$route.name === 'SignIn') {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            forceCloseBtn: true,
            center: false,
            force: true,
          });
        }

        if (this.isFullAuth && this.$route.name === 'SignIn') {
          this.$router.replace({ path: '/' });
        }
        clearTimeout(timeout);
      }, 2000);
    },
    unmounted() {
      document.removeEventListener('scroll', this.newScrollPosition);
    },
    destroyed() {
      this.$bus.$off('ToggleCatalog');
    },
    methods: {
      newScrollPosition() {
        if (this.toggleStatus || window.pageYOffset < 0) {
          return;
        }
        const scrollPosition = document.documentElement.scrollTop;
        if (!this.activePopup) {
          this.hide =
            scrollPosition > this.lastScroll && device.isMobileSize()
              ? 'hide'
              : '';
        }
        this.lastScroll = scrollPosition;
      },
      formatDate,
      webviewRegister() {
        activateFeature('webview_feature');
        this.$bus.$emit('toggleAuthPopup', {
          component: 'register',
          center: false,
          force: true,
        });
      },
      webviewRestorePass() {
        activateFeature('webview_feature');
        this.$bus.$emit('toggleAuthPopup', {
          component: 'forgot',
          center: false,
          force: true,
        });
      },
      closeSearchProductPopup() {
        this.$emit('closeProductPopup');
      },
      toggleSideBar(event) {
        event.stopPropagation();
        this.isActiveSidebar = !this.isActiveSidebar;
        if (this.isActiveSidebar) {
          this.$body.openSideBar();
        } else {
          // // this.$body.closeModal();
        }
      },
      logout() {
        const CONFIRM_CONFIG = {
          centered: true,
          size: 'confirm',
          cancelTitle: 'No',
          okTitle: 'Yes',
        };

        this.$bvModal
          .msgBoxConfirm(
            'Do you really want to sign out of MARKET',
            CONFIRM_CONFIG,
          )
          .then(async (confirm) => {
            if (confirm) {
              await Auth.logOut();
              WishlistService.clear();
              this.$bus.$emit('logout');
              if (
                this.$route.path.includes('wishlist') ||
                this.$route.path.includes('my-profile')
              ) {
                this.$router.push('/');
              }
            }
          });
      },
      showAuthPopup() {
        let isZman = process.env.VUE_APP_CUSTOMER === 'zman';
        this.$bus.$emit('toggleAuthPopup', {
          component: isZman ? 'register' : 'login',
          forceCloseBtn: true,
          center: false,
          force: true,
        });
      },
      closeAuthPopup() {
        if (!this.isMiniatureFrame) {
          // this.$bus.$emit('togglePopupVOD');
          this.$bus.$emit('setMiniplayer');
          this.$bus.$emit('toggleFullScreenIframe');
        }
        this.$bus.$emit('toggleProductPopup');
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          force: false,
        });
      },
      appLogo() {
        return device.isMobileScreen()
          ? require('src/assets/logo/logo-market-verb.png')
          : require('src/assets/logo/logo-market-verb.png');
      },
      appTitle() {
        return Config.get('app_title') || 'Portal';
      },
      onLogin() {
        this.showAuthPopup();
      },
      toggleCatalog() {
        this.$bus.$emit('ToggleCatalog', !this.showCatalog);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './AppHeader';
</style>
