export default `
  query checkout($checkoutId: ID!) {
    node(id: $checkoutId) {
      id
      ... on Checkout {
        completedAt
        webUrl
        lineItems(first: 250) {
          edges {
            node {
              id
              title
              quantity
              customAttributes {
                key
                value
              }
              variant {
                id
                price {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;