import createCheckoutMutation from './mutations/createCheckout';
import customerAccessTokenCreateWithMultipassMutation from './mutations/customerAccessTokenCreateWithMultipass';
import associateCustomerWithCheckoutMutation from './mutations/associateCustomerWithCheckout';
import checkoutLineItemsAddMutation from './mutations/checkoutLineItemsAdd';
import customerQuery from './queries/customer';
import checkoutQuery from './queries/checkout';
import { amsClient } from '@/service/ams';

export default new (class {
  #accessToken = null;
  #endpoint = null;

  async createCheckout() {
    const { checkoutCreate } = await this.#makeRequest(createCheckoutMutation);

    return checkoutCreate.checkout;
  }

  async getCheckout(checkoutId) {
    const { node } = await this.#makeRequest(checkoutQuery, { checkoutId });

    return node;
  }

  async addItemsToCheckout(checkoutId, lineItems) {
    const result = await this.#makeRequest(checkoutLineItemsAddMutation, { lineItems, checkoutId });
  }

  async getCustomerAccessTokenWithMultipass(multipassToken) {
    const { customerAccessTokenCreateWithMultipass } = await this.#makeRequest(
      customerAccessTokenCreateWithMultipassMutation,
      { multipassToken },
    );

    return customerAccessTokenCreateWithMultipass.customerAccessToken.accessToken;
  }

  async getCustomerByAccessToken(customerAccessToken) {
    const { customer } = await this.#makeRequest(customerQuery, { customerAccessToken });

    return customer;
  }

  async associateCustomerWithCheckout(checkoutId, customerAccessToken) {
    const { checkoutCustomerAssociateV2 } = await this.#makeRequest(
      associateCustomerWithCheckoutMutation,
      { checkoutId, customerAccessToken },
    );

    return checkoutCustomerAssociateV2;
  }

  async #makeRequest(query, variables = {}) {
    if (!this.#accessToken || !this.#endpoint) {
      this.#accessToken = amsClient.get('conf.portal.marketplace.shopify.storefront_token');
      this.#endpoint = amsClient.get('conf.portal.marketplace.shopify.shopify_api_endpoint');
    }

    const response = await fetch(`${this.#endpoint}/api/2021-10/graphql.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': this.#accessToken,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await response.json();

    if (json.errors) {
      throw new Error(json.errors[0].message);
    }

    return json.data;
  }
})();