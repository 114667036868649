export default (item,link = null) => {
	if (!item) {
		return '/';
	} else if (item.itype === 'screen_settings') {
		return `/set`;
	} else if (item.itype === 'item_epg_shop_event' && item?.is_live) {
		return `/s/${item.creator?.slug || item.slug || ''}`;
	} else if (item.itype === 'screen_tv') {
		return `/live/`;
	} else if (['item_live_virtual','item_live'].indexOf(item.itype) !== -1) {
		return `/live/?vid=${item.id}`;
	} else if (item.itype === 'item_app') {
		return (
			item.dl_web || item.schema_web || item.link
		);
	} else if (['item_mov_episode','section_season'].indexOf(item.itype) > -1) {
		let type = item.itype === 'item_mov_episode' ? 'series' : 'season';
		return `/serial/${type}/${item.id}`;
	} else if (['screen_shoprz','event_host_admin'].indexOf(item.itype) > -1) {
		return item.slug === "home" ? '/' : `/s/${item.slug || item.id}`;
	} else {
		return item.link || '';
	}
};
