import { localStore } from 'vimmi-web-utils/esm/localStore';

export default class ErrorPlugin {
  constructor() {
    this.lastError = localStore.get('lastError');
  }

  getLastError() {
    return this.lastError;
  }

  setError(err = null, type = 'app') {
    console.log(this)
    if (err instanceof String) {
      this.lastError = {
        text: err,
        type: type,
        title: 'Error script',
        code: '999',
        stack: (new Error()).stack,
      };
    } else if (err?.source === 'AMS' || err?.source === 'HTTP') {
      this.lastError = {
        text: err.source,
        title: err.message,
        page: location.href,
        type: type,
        code: err.code,
      };
    } /*else if(err.hasOwnProperty('request')) {
      this.lastError = {
        text: err.message,
        title: err.request.statusText,
        page: err.request.responseURL,
        type: type,
        code: err.request.status,
      };
    } else if(err instanceof Error) {
      this.lastError = {
        text: err.message,
        title: 'Exeption Error',
        code: '666',
        type: type,
        stack: err.stack,
      };
    }*/ else {
      this.lastError = err;
      if (!this.lastError.stack) {
        this.lastError.stack = (new Error()).stack;
      }
      if (!this.lastError.type) {
        this.lastError.type = type;
      }
    }
    console.log(this.lastError);
    localStore.set('lastError', this.lastError);
  }
}
