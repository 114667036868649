<template>
  <div>
    <b-modal
      v-model="showModal"
      :size="'lg'"
      :modal-class="'sharing-dialog'"
      :centered="true"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="sharing-popup">
        <button class="close-btn" @click="closeModal">
          <img :src="require(`@/assets/icons/icon-close.svg`)">
        </button>
        <div class="sharing-popup__title">
          <span>{{ currentStoreName }}</span>
        </div>
        <div class="sharing-popup__close" />
        <div class="divider" />
        <div class="sharing-popup__items">
          <span>{{ $t('SHARING.SHARE') }}</span>
          <ul class="sharing-popup__sicial-list">
            <li class="block-sicial">
              <div class="sicial-list__item">
                <a :href="`mailto:?body=${bodyMailTo}${currentStoreLink}&subject=${subjectMailto}`"
                   @click="sendStats('Email')">
                  <div class="social-list__icon">
                    <img class="icon-social" :src="require(`@/assets/icons/icon-mail.svg`)" alt="">
                  </div>
                </a>
              </div>
              <div class="icon-title">
                Email
              </div>
            </li>

            <li class="block-sicial">
              <div class="sicial-list__item">
                <a
                  target="_blank"
                  class="fb-share-button social-list__icon icon-facebook"
                  @click="share();"
                >
                  <img
                    :src="require(`@/assets/icons/icon-fb.svg`)"
                    class="icon-social"
                  >
                </a>
              </div>
              <div class="icon-title">
                Facebook
              </div>
            </li>

            <li class="block-sicial">
              <div class="sicial-list__item">
                <a
                  class="social-list__icon"
                  :href="`https://twitter.com/intent/tweet?&url=${currentStoreLink}`"
                  data-size="large"
                  target="_blank"
                  @click="sendStats('Twitter')"
                >
                  <img
                    :src="require(`@/assets/icons/icon-twitter.png`)"
                    class="icon-social"
                  >
                </a>
              </div>
              <div class="icon-title">
                Twitter
              </div>
            </li>
          </ul>
        </div>
        <div class="divider" />
        <div class="sharing-popup__link">
          <span>{{ $t('SHARING.LINK') }}</span>
          <div class="link-wrap">
            <input :value="currentStoreLink" type="text" disabled>
            <button class="link-btn" @click="copyLink()">
              Copy
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-toast id="toast" toast-class="info-toast" :toaster="toastPosition" auto-hide-delay="5000" no-close-button>
      <div>
        <b-img :src="require(`@/assets/icons/icon-copied.svg`)" />
        <span>Link copied to clipboard</span>
      </div>
    </b-toast>
  </div>
</template>
<script>
  export default {
    name: 'SharingDialog',
    data() {
      return {
        showModal: false,
        currentItem: null,
        currentStoreLink: null,
        currentStoreName: null,
        bodyMailTo: 'Hi, look at: ',
        subjectMailto: 'Subject mail',
      };
    },
    computed: {
      toastPosition() {
        return window.innerWidth >= 1100 ? 'b-toaster-bottom-left' : 'b-toaster-bottom-center';
      },
    },
    watch: {
      showModal(val) {
        this.$emit('change:modal', 'sharingModal', val);
      },
    },
    mounted() {
      window.twttr = (function(d, s, id) {
        let js,
          fjs = d.getElementsByTagName(s)[0],
          t = window.twttr || {};
        if (d.getElementById(id)) return t;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://platform.twitter.com/widgets.js';
        fjs.parentNode.insertBefore(js, fjs);

        t._e = [];
        t.ready = function(f) {
          t._e.push(f);
        };

        return t;
      })(document, 'script', 'twitter-wjs');

      this.$bus.$on('ToggleSharingDialog', ({ currentStoreLink, currentStoreName, item }) => {
        if (!currentStoreLink || !currentStoreName) {
          this.closeModal();

          return;
        }

        this.showModal = true;
        this.currentItem = item;
        this.currentStoreLink = currentStoreLink;
        this.currentStoreName = currentStoreName;
      });
    },
    methods: {
      closeModal() {
        this.showModal = false;
        this.currentStoreLink = null;
        this.currentStoreName = null;
        this.currentItem = null;
      },
      copyLink() {
        this.sendStats('Copy Link');
        navigator.clipboard.writeText(this.currentStoreLink);
        this.$bvToast.show('toast');
      },
      sendStats(method) {
        const options = this.$stats.formatEventDate(this.currentItem)
        this.$stats.send('share', options, {});

        if (['VOD', 'Storefront', 'Live Event', 'Upcoming Live Event'].includes(options.gtag.content_type)) {
          this.$gtag('event', 'share', {
            method,
            ...options.gtag,
          });
        }
      },
      share() {
        this.sendStats('Facebook');
        FB.ui(
          {
            method: 'share',
            href: this.currentStoreLink,
          },
          function() {
          },
        );
      },
    },
  };
</script>
<style lang="scss">
  #toast {
    &.info-toast {
      margin-left: 30px;
      background: #202123;
      border-radius: 10px;
      padding: 25px;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      span {
        margin-left: 12px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .sharing-popup {
    position: relative;
    max-width: 480px;
    margin: auto;
    padding: 14px 20px 25px;
    background-color: #fff;
    z-index: 1150;

    &__title {
      span {
        color: $sharing-text-color;
      }
    }

    .divider {
      max-width: 440px;
      height: 1px;
      background-color: $sharing-divider-color;
    }

    &__items {
      margin-top: 16px;
    }

    &__sicial-list {
      margin-top: 8px;
      margin-bottom: 21px;
      padding: 0px;
      list-style-type: none;
      display: flex;

      .block-sicial {
        margin-left: 15px;

        .sicial-list__item {
          display: flex;
          justify-content: center;

          .social-list__icon {
            padding: 20px;
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            background-color: $sharing-background-icon-color;

            .icon-social {
              width: 24px;
              height: 20px;
              border-radius: 0;
            }
          }

          .icon-facebook {
            background-color: $sharing-icon-color;
          }


        }

        .icon-title {
          text-align: center;
          margin-top: 9px;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    @media (max-width: 576px) {
      max-width: 355px;
      top: calc(100% - 174px);

      .icon-title {
        font-size: 14px;
      }

      .sharing-popup__title {
        span {
          font-size: 16px;
        }
      }

      .sharing-popup__items {
        span {
          font-size: 15px;
        }
      }

      .sharing-popup__link {
        span {
          font-size: 15px;
        }
      }

      .link-wrap {
        input {
          width: 218px;
        }

        .link-btn {
          padding: 14px 25px 13px;
        }
      }
    }
  }

  .sharing-popup__close {
    margin-bottom: 9px;
  }

  .link-wrap {
    margin-top: 9px;
    display: flex;
    align-items: center;

    input {
      width: 324px;
      padding: 14px;
      background-color: $sharing-input-color;
      outline: none;
      font-size: 14px;
      color: $sharing-text-color;
    }

    .link-btn {
      font-weight: 600;
      font-size: 15px;
      padding: 14px 35px 13px;
      margin-left: 6px;
      color: $text-white-color;
      background-color: $main-color;
      outline: none;
    }
  }

  .sharing-popup__link {
    margin-top: 16px;
  }

  .close-btn {
    right: 10px;
    top: 10px;
    position: absolute;
    border: none;
    outline: none;
  }
</style>