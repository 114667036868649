import Vue from 'vue';
import RTM from 'vimmi-web-utils/esm/realTimeMessages';
import store from '@/store';
// import PopupService from '@/service/popup';

const rtm = new RTM();

rtm.debug();

/* rtm.on('debug', data => {
  console.log('[rtm][debug]', data);
}); */

rtm.on(
  ['finished', 'on_air_now', 'started', 'failed', 'scheduled', 'close'],
  (data) => {
    // console.log('[events changed]', data);

    store.commit('dataAreasLive/setLives', {
      id: data.room,
      finished: false,
      end: false,
    });

    if (data.op === 'on_air_now') {
      Vue.prototype.$rtmWasInited = true;
    }

    if (data.op === 'finished') {
      const durationChangesTrigger =
        store.getters['dataAreasLive/durationChangesTrigger'];

      store.commit('dataAreasLive/changeStatusLive', {
        id: data.room,
        finished: true,
        end: durationChangesTrigger,
      });
    }

    const key = 'fastcastTimestamp';
    let timestamp = +data.msg?.timestamp || null;
    const savedTimestamp = +localStorage.getItem(key);

    if (timestamp < savedTimestamp) {
      timestamp = savedTimestamp;
    }

    Vue.prototype.$eventsTimestamp = timestamp;

    localStorage.setItem(key, +timestamp);
  },
);

rtm.setDomain(process.env.VUE_APP_FASTCAST_DOMAIN || 'int-verb.vidnt.com');

export default rtm;
