/**
 * StoreWrapperService Module
 * @module StoreWrapperService
 * @requires src/plugins/store
 */
import store from './index';

/**
 * @class StoreWrapperService
 */

/**
 * Callback subscribe function.
 *
 * @callback subscribe
 * @param {Object} obj An Object param of function
 * @property {Object} obj.store A Vuex store
 * @property {Object} obj.state A Vuex Module state
 * @property {Object} obj.mutation A Vuex Module state
 * @property {Object} obj.mutation.type A Vuex Mutation type for module
 * @property {Object} obj.mutation.payload A Vuex Mutation payload for module
 * @property {String} obj.name A Vuex Module name
 *
 * @memberof StoreWrapperService
 */

/**
 * Callback for init functions.
 *
 * @callback init
 * @param {Object} obj An Object param of function
 * @property {Object} obj.store A Vuex store
 * @property {Object} obj.state A Vuex Module state
 *
 * @memberof StoreWrapperService
 */

export default class StoreWrapperService {
  /**
   * Creates an instance of StoreWrapperService. If `name` and `localStore` not defiend return Error
   * Class Wrapper Vuex Store to use it without a this.$store[module name]
   *
   * @param {Object} options Options for object creation
   * @property {String} options.name Name of wrapper. Mandatory fields
   * @property {Object} options.localStore Store for wrapping. Mandatory fields.
   * @property {Object} [options.options] Options to enable/disable functions in objectt
   * @property {Boolean} options.options.mutations=false Toogle all Mutations
   * @property {Boolean} options.options.actions=true Toogle all Actions
   * @property {Boolean} options.options.getters=true Toogle all Getters
   * @property {Object} [options.ignore] The object with list to ignore function
   * @property {Array<String>} options.ignore.mutations The list of mutations, where should be ignored.
   * @property {Array<String>} options.ignore.actions The list of actions, where should be ignored.
   * @property {Array<String>} options.ignore.getters The list of getters, where should be ignored.
   * @property {init} [options.init] The init function
   * @property {subscribe} [options.subscribe] The subscribe function. If defined will be added subscribe in store.
   *
   * @memberof StoreWrapperService
   */
  constructor({
                name,
                localStore,
                options = {
                  mutations: false,
                  actions: true,
                  getters: true,
                },
                init,
                subscribe,
                ignore = {
                  mutations: [],
                  actions: [],
                  getters: [],
                },
              }) {
    this._$moduleName = name ? name : this._$mandatory('name');
    this._$localStore = localStore ? localStore : this._$mandatory('localStore');
    this._$options = options;
    this._$init = init;
    this._$subscribe = subscribe;
    this._$ignore = ignore;
    this._$initStore();
    this._$setGetters();
    this._$setActions();
    this._$setMutatations();
  }

  _$mandatory(name) {
    throw new Error(
      `The param '${name}' is mandaroty for Object '${this.constructor.name}'.`,
    );
  }

  _$setGetters() {
    if (this._$options.getters) {
      let getters = Object.keys(this._$localStore.getters).filter(
        el => this._$ignore.getters.indexOf(el) === -1,
      );
      getters.forEach(get => {
        this[get] = data => {
          return data
            ? store.getters[`${this._$moduleName}/${get}`](data)
            : store.getters[`${this._$moduleName}/${get}`];
        };
      });
    }
  }

  _$setActions() {
    if (this._$options.actions) {
      let actions = Object.keys(this._$localStore.actions).filter(
        el => this._$ignore.actions.indexOf(el) === -1,
      );
      actions.forEach(action => {
        this[action] = data => {
          return store.dispatch(`${this._$moduleName}/${action}`, data);
        };
      });
    }
  }

  _$setMutatations() {
    if (this._$options.mutations) {
      let mutations = Object.keys(this._$localStore.mutations).filter(
        el => this._$ignore.mutations.indexOf(el) === -1,
      );
      mutations.forEach(mutation => {
        this[mutation] = data => {
          return store.commit(`${this._$moduleName}/${mutation}`, data);
        };
      });
    }
  }

  _$isModuleregistered() {
    let res = store && store.state[`${this._$moduleName}`];
    return res;
  }

  _$initStore() {
    if (!this._$isModuleregistered()) {
      store.registerModule(this._$moduleName, this._$localStore);
      this._$setGetters();
      this._$setActions();
      this._$setMutatations();
      if (this._$init) {
        this._$init({ store: store, name: this._$moduleName });
      }
      if (this._$subscribe) {
        store.subscribe((mutation, state) => {
          let type = mutation.type;
          if (type.search(new RegExp(`^${this._$moduleName}/`)) === 0) {
            let childMutationName = mutation.type.slice(
              this._$moduleName.length + 1,
            );
            let childMutation = {
              type: childMutationName,
              payload: mutation.payload,
            };
            this._$subscribe({
              store: store,
              name: this._$moduleName,
              mutation: childMutation,
              state: state[this._$moduleName],
            });
          }
        });
      }
    }
  }
}
