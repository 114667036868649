<template>
  <div ref="popupWrapper" class="message-popup">
    <div ref="popup" class="message-popup__container">
      <button class="message-popup__close" @click="closeMessagePopup()">
        <img src="../../assets/icons/close_btn_dark.png" alt="Close">
      </button>
      <h1 class="message-popup__title">
        This Live Event has reached its maximum attendance.<br>
        Please click here to visit the {{ storeName }}
      </h1>
      <div class="message-popup__actions">
        <button
          class="message-popup__button"
          @click="handleRedirect()"
        >
          Go to Storefront
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MessagePopup',
    props: {
      storeLink: {
        type: String,
        required: true,
      },
      storeName: {
        type: String,
        required: true,
      },
    },
    methods: {
      handleRedirect() {
        this.closeMessagePopup();
        this.$router.push(`channel/${this.storeLink}`);
      },
      closeMessagePopup() {
        this.$bus.$emit('toggleMessagePopup', { item: null });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "./message-popup";
</style>
