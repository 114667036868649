// import Vue from 'vue';
// import eventBus from "src/service/eventBus";
import { vapp } from '../main';
import stripHTML from '../filters/stripHtml';

let canvas = document.createElement('canvas');
let stripTagDiv = document.createElement('div');
canvas.hidden = true;
canvas.width = '1px';
canvas.height = '1px';
let ctx = canvas.getContext('2d');
let DEFAULT_WIDTH_ELEMENT = 200;

function transformText(width, _text, rows, symbolPix) {
  let words = _text.split(' ');
  let _strings = [];
  let _currentIndex = 0;
  words.map(function(word) {
    if (_currentIndex + 1 <= rows) {
      if (!_strings[_currentIndex]) {
        _strings[_currentIndex] = word;
      } else if (
        (_strings[_currentIndex] + ' ' + word).length * symbolPix + 10 <
        width - (_currentIndex + 1 === rows ? symbolPix * 4 : 0)
      ) {
        _strings[_currentIndex] = _strings[_currentIndex] + ' ' + word;
      } else {
        _currentIndex += 1;
        if (_currentIndex + 1 <= rows) {
          _strings[_currentIndex] = word;
        }
      }
    }
  });
  // console.log(_strings);
  return _strings.join(' ');
}

function getFont(styles) {
  let font = styles.font;
  if (!font) {
    font =
      styles.fontWeight +
      ' ' +
      styles.fontStyle +
      ' ' +
      styles.fontVariant +
      ' ' +
      styles.fontSize +
      '/' +
      styles.lineHeight +
      ' ' +
      styles.fontFamily;
  }
  return font;
}

// variant with word wrap
let trimText = (el, bindings) => {
  let text = bindings.value.content;
  stripTagDiv.innerHTML = text || '';
  text = stripTagDiv.innerText.replace('\n', '').replace('\r', '');
  if (text) {
    let styles = getComputedStyle(el);
    let paddingLn =
      parseInt(styles.paddingLeft) + parseInt(styles.paddingRight);
    let paddingHg =
      parseInt(styles.paddingTop) + parseInt(styles.paddingBottom);
    let maxHeight = parseInt(styles.maxHeight);
    let height = parseInt(styles.height);
    let isDisplay = styles.display !== 'none';
    // console.log (styles);

    let ht =
      maxHeight > 0 && !isNaN(maxHeight)
        ? maxHeight
        : height > 0 && isNaN(height)
          ? height
          : el.clientHeight;
    ctx.font = getFont(styles);

    let metrics = ctx.measureText(text);
    let symbolPix = metrics.width / text.length;

    // if (text === 'At the Office Lorem ipsum dolor sit amet 222222222 22222222222') {
    //   console.log(paddingLn, paddingHg, maxHeight, height, symbolPix, metrics.width, el, getFont(styles));
    // }
    let rows = Math.floor((ht - paddingHg) / parseInt(styles.lineHeight));
    rows = rows <= 1 ? 1 : rows;
    // If element is display none or not mounted set default width as DEFAULT_WIDTH_ELEMENT = 200
    let width =
      isDisplay && el.clientWidth > 0
        ? el.clientWidth - paddingLn
        : DEFAULT_WIDTH_ELEMENT;
    let toSlice = width * rows;

    if (toSlice > metrics.width) {
      el.textContent = transformText(width, text, rows, symbolPix);
    } else {
      let widthPer = (toSlice - rows) / (metrics.width / 100);
      let sliceLn = (text.length / 100) * widthPer;

      let _text = text.substr(0, Math.floor(sliceLn));

      if (_text[_text.length - 1] !== ' ' && text[_text.length] !== ' ') {
        _text = _text.substr(0, _text.lastIndexOf(' '));
      }
      el.textContent = transformText(width, _text, rows, symbolPix) + '...';
    }
    el.style.visibility = 'visible';
  }
};

function getIntValue(value, defaultValue = null) {
  let val = parseInt(value);
  return !isNaN(val) ? val : defaultValue;
}

function checkPositive(value, defaultReturn = 0) {
  return value > 0 ? value : defaultReturn;
}

function calculateHeight(styles, defaultHeight = 0) {
  let maxHeight = getIntValue(styles.maxHeight);
  let height = getIntValue(styles.height);
  let ht = maxHeight > 0 ? maxHeight : height > 0 ? height : defaultHeight;

  let paddingTop =
    getIntValue(styles.paddingTop, 0) + getIntValue(styles.paddingBottom, 0);
  return checkPositive(ht - paddingTop, 0);
}

function calculateWidth(styles, width) {
  let paddingLn =
    getIntValue(styles.paddingLeft, 0) + getIntValue(styles.paddingRight, 0);
  let wdth = Math.round(checkPositive(width - paddingLn));
  return wdth;
}

function getNormalizeText(text, nativeRow = false) {
  let replaceRow = nativeRow ? ' <br/> ' : '';
  let txtRows = text.replace('\n', replaceRow);
  return txtRows;
}

function spaceIndexOf(text = '', start = 0, end = 0, last = false) {
  if (text && text.length > 0) {
    let txt = text.slice(start, end);
    // console.log(txt, start, end, text.length);
    let regexp = last ? /[\s|-]+(?!.*[\s|-])/gi : /[\s|-]+/i;
    regexp.lastIndex = start;
    let res = txt.search(regexp);
    return res;
  }
  return -1;
}

function getWordWidth(val) {
  // this.ctx.font = this.getFont(styles);
  let metrics = ctx.measureText(val);
  return Math.round(metrics.width);
}

function findStringByWidth(text, width) {
  if (text && text.length > 0) {
    let textWidth = getWordWidth(text);
    let index = Math.min(
      Math.floor((width / textWidth) * text.length),
      text.length,
    );
    let lastSpace =
      index !== text.length ? spaceIndexOf(text, 0, index + 1, true) : -1;
      // console.log(text);
      // console.log(textWidth, width, index, lastSpace);

    if (lastSpace > -1) {
      let norm = Math.min(lastSpace + 1, text.length - 1);
      let res = text.slice(0, norm);
      if (getWordWidth(res) >= width) {
        return findStringByWidth(res, width);
      }
      return res;
    }
    return text;
  } else {
    return text;
  }
}

function transformByTextLen(text, rows, width, nativeRow = false) {
  let txtRows = getNormalizeText(text, nativeRow);
  let endSymbolsWidth = getWordWidth(stripHTML('...'));
  let strings = [];
  let textLenght = txtRows.length;
  let rowsIndex = (width * rows) / getWordWidth(txtRows);
  if (rowsIndex < 1) {
    txtRows = txtRows.slice(0, Math.floor((rowsIndex + 0.1) * textLenght));
    textLenght = txtRows.length;
  }
  // console.group(name);
  for (
    let currentStrings = 0;
    currentStrings < rows && textLenght > 0;
    currentStrings++
  ) {
    let isLastRow = !(currentStrings + 1 < rows);
    let str = findStringByWidth(txtRows, width);
    let sliceIndex = Math.min(str.length, txtRows.length);

    // NOTE: if '\n' is first item ignore it
    if (nativeRow && str.indexOf('<br/>') > 0) {
      sliceIndex = str.indexOf('<br/>');
      str = str.slice(0, sliceIndex);
    }

    txtRows = txtRows.slice(sliceIndex);
    strings.push(str);

    if (isLastRow && txtRows.length > 0) {
      str = findStringByWidth(str, width - endSymbolsWidth);
      str += '&hellip;';
      // console.log(width, endSymbolsWidth, width - endSymbolsWidth);
      // console.log(str);
      strings.splice(currentStrings, 1, str);
    }
    textLenght = txtRows.length;
  }
  let res = strings.join('');
  return res;
}

let trimTextLen = (el, bindings) => {
  let text = stripHTML(bindings.value.content); //.replace('\n', ' ').replace('\r', '');
  if (text) {
    let styles = getComputedStyle(el);

    // get Symbol width
    ctx.font = getFont(styles);
    // let endMetrics = this.ctx.measureText('...');
    // // let metrics = this.ctx.measureText(textMeasure);
    // let currentWidth = this.getWordWidth(textMeasure); //metrics.width;
    // let symbolPix = currentWidth / textMeasure.length;

    // Get Rows
    let htElem = calculateHeight(styles, el.clientHeight);
    let lineHeight = getIntValue(styles.lineHeight, 1);
    let rows = Math.max(Math.floor(htElem / lineHeight), 1);

    // Get target Width
    let width = checkPositive(calculateWidth(styles, el.clientWidth), 200);
    // let targetWidth = width * rows;
    el.style.visibility = 'visible';

    el.innerHTML = transformByTextLen(text, rows, width);
    return true;
    // } else {
    //   return this.nativeRow ? text.replace('\n', '<br/>') : text;
    // }
  } else {
    el.style.visibility = 'visible';
    el.innerHTML = '';
    return true;
  }
};

export default {
  bind: (el, bindings) => {
    if (bindings.value.resize) {
      el.event = trimTextLen.bind(null, el, bindings);
      vapp.$bus.$on('resizeWindow', el.event);
    }
  },
  inserted: (el, bindings) => {
    let text = bindings.value.content;
    // el.innerHTML = text;
    el.style.visibility = 'hidden';
    // trimText(el, bindings)
    setTimeout(trimTextLen, 0, el, bindings);
  },
  update: (el, bindings) => {
    // trimText(el, bindings);
    let text = bindings.value.content;
    // el.innerHTML = text;
    el.style.visibility = 'hidden';
    // trimText(el, bindings)
    setTimeout(trimTextLen, 0, el, bindings);
  },
  unbind: (el, bindings) => {
    if (bindings.value.resize) {
      // $(el).$off('resizeWindow', trimText);
      vapp.$bus.$off('resizeWindow', el.event);
      // console.log('unresize');
    }
  },
};
