<template>
  <div>
    <button
      :class="{'follow-btn-followed' : isFollowed(), [variant]: variant}"
      @click="toggleFollow(id)"
    >
      {{ isFollowToggle }}
    </button>
  </div>
</template>
<script>
  import Auth from 'src/service/authService';
  import ShoprzStore from 'src/store/shoprz';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'FollowButton',
    props: {
      id: {
        type: String,
        required: true,
      },
      storeId: {
        type: String,
        default: null,
      },
      variant: {
        type: String,
        default: () => '',
      },
    },
    computed: {
      isFollowToggle() {
        return this.isFollowed() ? 'Following' : 'Follow';
      },
    },
    methods: {
      isFollowed() {
        return ShoprzStore.isChannelFollowed(this.id);
      },
      toggleFollow(creatorId) {
        if (!Auth.isAuth() || Auth.isGuest()) {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            forceCloseBtn: true,
          });
          return;
        }
        if (this.isFollowed()) {
          $stats.send('channel_unfollow', {
            channel_id: creatorId,
            store_id: 'verb',
          });
        } else {
          $stats.send('channel_follow', {
            channel_id: creatorId,
            store_id: 'verb',
          });
        }
        ShoprzStore.toggleFollow(creatorId);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .follow-btn {
    width: 132px;
    height: 32px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: $seller-dark-blue;
    color: #FFFFFF;
    border: none;
    outline: none;

    &-followed {
      background-color: transparent;
      border: 1px solid $seller-dark-blue;
      color: $seller-dark-blue;
    }

    &:hover {
      background-color: $hover-btn-bgcolor;
      color: $seller-white;
    }
  }

</style>