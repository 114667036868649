const state = {
    isTermsAccepted: false,
};

const getters = {
    isTermsAccepted: state => {
        return state.isTermsAccepted;
    },
};

const mutations = {
    toggleTermsStatus(state) {
        state.isTermsAccepted = !state.isTermsAccepted;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};