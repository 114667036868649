<template>
  <div class="cart">
    <button class="btn cart-btn" @click="openCart()">
      <span>Cart</span>
      <img :src="require('src/assets/icons/cart_icon.svg')" alt="Cart">
      <div v-if="cartItemsCount" class="btn-pin">
        {{ cartItemsCount }}
      </div>
    </button>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { amsClient } from '@/service/ams';
  import CheckoutService from '@/store/checkout';

  export default {
    name: 'Cart',
    computed: {
      ...mapState('checkout', ['cartItemsCount']),
    },
    methods: {
      async openCart() {
        const cartUrl = amsClient.get('conf.portal.marketplace.shopify.cart');

        this.$bus.$emit('toggleShopCart', { item: {
          link: `${cartUrl}?c_id=${CheckoutService.getAvailableCheckoutId()}`,
          title: "My Cart"
        }});
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import './Cart';
</style>
