<template>
  <div
    v-if="currentItem"
    class="full-page-iframe"
    :style="options.styles"
    @click.stop="closeModal"
  >
    <div class="product-popup__container">
      <iframe
        ref="productIframe"
        width="100%"
        height="100%"
        class="product-popup__frame"
        :src="initProductLink"
        frameborder="0"
      />
    </div>
    <div class="product-popup">
      <button class="product-popup__button" @click.stop="closeModal">
        <img
          :src="require('/src/assets/icons/close_btn_dark.png')"
          alt="Close button image"
        >
      </button>
      <product-vod-popup
        v-if="openVodPlayer"
        :item="item"
        @closeProductPopup="closeModal"
      />
    </div>
  </div>
</template>

<script>
  import GtagService from '@/service/gtag';
  import CheckoutService from '@/store/checkout';
  import { amsClient } from '@/service/ams';
  import device from '@/service/device-service';
  import { PM } from 'vimmi-web-utils/cjs/postMessage';
  import WishlistService from '@/store/wishlist';
  import Auth from '@/service/authService';
  import ProductVodPopup from '@/components/product-vod-popup/product-vod-popup';
  import { mapGetters } from 'vuex';
  import Vue from 'vue';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'ProductPopup',
    components: { ProductVodPopup },
    props: {
      item: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: () => {
        },
      },
    },
    data: function() {
      return {
        updatedItem: null,
        productUrl: null,
        styles: {},
        openVodPlayer: false,
        prevTitle: document.title,
      };
    },
    computed: {
      ...mapGetters({
        productLink: 'upcomingModule/productUrl',
        miniPlayerFromProduct: 'popup/miniPlayerFromProduct',
      }),
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      initProductLink() {
        return this.item.itype === 'item_epg_shop_event'
          ? this.productLink
          : this.productUrl;
      },
      seoTitle() {
        return this.item.title;
      },
      seoDescription() {
        return this.item.description.replaceAll(/<[^>]*>/g, '');
      },
      seoImage() {
        return this.item.poster;
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      refId() {
        const { ref } = this.currentItem;
        const source = this.getSourceName(ref?.source);

        const data = {
          source,
          room: source === 'page' ? 'lobby' : ref.id,
          product_id: this.currentItem.id,
          page_url: window.location.href,
        };

        return window.btoa(JSON.stringify(data));
      },
      currentItem() {
        return { ...(this.updatedItem || this.item) };
      },
      currentLive() {
        return this.$store.getters['dataAreasLive/currentLive'];
      },
      currenSecondLive() {
        return this.$store.getters['dataAreasLive/currenSecondLive'];
      },
      isMobile() {
        return device.isAnyMobile();
      },
    },
    watch: {
      currentItem(item) {
        this.$bus.$emit('updateTitle', item?.seo_metadata?.title);
      },
    },
    async mounted() {
      Vue.prototype.$productIframeRef = this.$refs.productIframe;

      this.productUrl = this.getProductUrl();

      const rfsn_v4_aid = localStorage.getItem('rfsn_v4_aid');
      const rfsn_v4_cs = localStorage.getItem('rfsn_v4_cs');

      if (rfsn_v4_aid && rfsn_v4_cs) {
        const rfsnCode = localStorage.getItem('rfsn_v4_aid') + '.' + localStorage.getItem('rfsn_v4_cs');

        const updateUrl = this.productUrl.split('?');

        updateUrl[1] = `rfsn=${rfsnCode}&${updateUrl[1]}`;

        this.productUrl = updateUrl.join('?');
      }

      await this.handleShopifyPostMessages();

      const creator = this.currentItem.store || this.currentItem.creator;
      if (this.options.float || this.options.float === undefined) {
        if (this.currentLive || this.currenSecondLive) {
          this.openVodPlayer = false;
        } else {
          this.openVodPlayer = !this.isMobile && !this.isMiniatureIframe;
        }
      }


      this.$bus.$emit('setCurrentPage', this.currentItem.share);

      const { ref } = this.currentItem;

      const availableCheckoutId = CheckoutService.getAvailableCheckoutId();
      const source = this.getSourceName(ref?.source);
      if (creator?.id) {
        GtagService.sendEvent('select_item', {
          store_id: creator.id,
          store_slug: creator.slug,
          store_name: creator.name,
          item_list_id: ref?.id,
          item_list_name: source,
          checkout_id: availableCheckoutId,
          items: [
            {
              item_id: this.currentItem.id,
              item_name: this.currentItem.title,
              affiliation: creator.name,
            },
          ],
        });
      }
    },
    beforeDestroy() {
      Vue.prototype.$productIframeRef = null;
      this.$bus.$emit('setCurrentPage', null);
      PM.inst().off('shopify');
      this.$store.commit('player/SET_VOD_ITEM', null);
    },
    methods: {
      closeModal() {
        this.$emit('closeProductPopup');
        this.$bus.$emit('updateTitle', this.prevTitle);
        if (this.miniPlayerFromProduct) {
          this.$bus.$emit('handleCloseFloatPlayer');
        }
      },
      getSourceName(value) {
        const source = value || 'page';
        return ['page', 'vod'].includes(source) ? source : 'live';
      },
      getProductUrl() {
        const availableCheckoutId = CheckoutService.getAvailableCheckoutId();
        const inWishlist = WishlistService.getList()[this.item.id];

        const url = availableCheckoutId
          ? `${this.currentItem.link}?c_id=${availableCheckoutId}&ref_id=${this.refId}`
          : `${this.currentItem.link}?ref_id=${this.refId}`;

        return `${url}&in_wishlist=${inWishlist ? 1 : 0}`;
      },
      handleShopifyPostMessages() {
        return new Promise((resolve) => {
          PM.inst().on('shopify', async (data) => {

            console.log('[Portal] handleShopifyPostMessages', data);

            const eventName = data.event;

            switch (eventName) {
              case 'addToWishlist':
                $stats.send('add_wishlist', {
                  product_id: this.item.id,
                  channel_id: this.item?.creator?.id,
                  store_id: 'verb',
                });
                if (Auth.isGuest()) {
                  // PopupService.skipAccountPopup(true);
                  this.showAuthPopup();

                  localStorage.setItem('addToWishlist', this.item.id);

                  return;
                }

                WishlistService.addToWishlist(this.item.id);

                break;
              case 'removeFromWishlist':
                $stats.send('remove_wishlist', {
                  product_id: this.item.id,
                  channel_id: this.item?.creator?.id,
                  store_id: 'verb',
                });
                if (Auth.isGuest()) {
                  // PopupService.skipAccountPopup(true);
                  this.showAuthPopup();

                  localStorage.removeItem('addToWishlist', this.item.id);

                  return;
                }

                WishlistService.removeFromWishlist(this.item.id);

                break;
              case 'toggleZendesk':
                zE('webWidget', 'close');
                zE('webWidget', 'reset');
                zE('webWidget', 'show');
                zE('webWidget', 'open');
                zE('webWidget', 'helpCenter:setSuggestions', { search: '' });

                break;
              case 'share':
                /* if (!this.currentItem?.store) {
                  return;
                } */

                const storeName = this.currentItem.store?.name ?? this.currentItem.creator.name;

                this.$bus.$emit('ToggleSharingDialog', {
                  currentStoreLink: `${this.$location}${this.currentItem.share}`,
                  currentStoreName: storeName,
                  item: this.currentItem,
                });

                break;
              case 'goToStore':
                const currentStore = this.$route.params.id === this.currentItem.store.slug;
                if (currentStore) {
                  return;
                }

                this.closeModal();
                this.$bus.$emit('ToggleScheduleEventDialog');
                this.$router.push(`/s/${this.currentItem.store.slug}`);
                break;
              case 'getProductStore':
                const target = this.$refs.productIframe;

                const { head } = await amsClient.callAms(
                  `/shoprzapi/product/${this.currentItem.id}/?by=id`,
                );

                this.$gtag('event', 'view_item', {
                  items: [{
                    item_id: head.id,
                    item_name: head.title,
                    store_name: head.store.name,
                    price: head.price,
                  }],
                });

                this.updatedItem = head;

                resolve();

                const store = this.currentItem.store;

                const pmData = {
                  event: 'storeData',
                  error: null,
                  data: {
                    name: store.title,
                    page: store.slug,
                    picture: store.picture,
                    followers: store.followers,
                    description: store.description,
                  },
                  key: data.data.productId,
                };

                console.log('storeData PM', pmData, target);

                PM.inst().send({
                  target,
                  type: 'portal',
                  data: pmData,
                });

                break;
            }
          });
        });
      },
      showAuthPopup() {
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          forceCloseBtn: true,
          center: false,
          force: true,
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .full-page-iframe {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    left: 0;
    z-index: 1039;
    padding-top: 0;
    background: #000000a8;

    iframe {
      border: 0;
    }

    & + .vod-popup-container {
      z-index: 1100;
    }
  }

  .product-popup__container {
    background-color: #fff;
  }

  .product-popup {
    position: relative;

    &__container {
      margin-top: 72px;
      max-height: 88%;
      width: 1200px;

      @include mobile-large {
        margin-top: 55px !important;
        max-height: calc(100% - 55px) !important;
      }
    }

    &__button {
      background-color: transparent;
      border: 0;
      position: absolute;
      right: 20px;
      top: 85px;
    }

    &__frame {
      border-radius: 5px;
    }
  }
</style>