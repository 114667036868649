import { Logger } from 'logger-js';

import VimmiAI from 'vimmi-analytics-integration/dist/vimmi-analytics-integration.cjs';
import { isActiveFeature } from 'src/plugins/feature-toggle';
import AnalyticsUtils from './utils';
import { amsClient } from '@/service/ams';
import Auth from '../../service/authService';
import { ConfigService } from 'vimmi-web-utils/esm/configService';
import Config from '../../service/config';

export default class Analytics extends ConfigService {

  constructor() {
    super();
    this.collectData = {};
    this.logger = new Logger('Analytics');
    this.utils = AnalyticsUtils;

    if (isActiveFeature('shoprz-analytics')) {
      this.analyticModule = amsClient.vimmiAIInit(VimmiAI, { drivers: [VimmiAI.DRIVERS.SHOPRZ] });
      this.analyticModule.conf({
        customPath: '/batch',
      }, null, VimmiAI.DRIVERS.SHOPRZ);
      amsClient.attachAnalytics(this);
    }
  }

  _getEventData(event, options, event_extra_data) {

    /** todo: separate event data by driver */
    let data = {
      // app_info: Config.get('app_info'),
      // app_version: Config.get('version'),
      // customer: process.env.VUE_APP_CUSTOMER.toLowerCase(),
      // session_id: Auth.get('user.sid'),
      // server: location.host,
      user_id: Auth.get('user.id'),
      refferal_url: document.referrer || undefined,
      classification: !!amsClient.get('user.guest') ? 'guest' : 'registered',
    };

    let screen = this.get('current.screen') || {};
    const itemId = event_extra_data.item_id || event_extra_data.media_id;
    switch (event) {
      case 'is_alive ':
      case 'session_start':
        data = Object.assign(data, {
          session_id: Auth.get('user.sid'),
        });
        break;
      case 'page_opened':
        Config.set('temp.page_type', event_extra_data.page_type);
        break;
      case 'registration':
      case 'login':
        let authType = Auth.get('user.login_type');
        if (authType === 'silent') {
          authType = 'guest';
        } else if (authType === 'default') {
          authType = 'standard';
        }
        data = Object.assign(data, {
          auth_type: authType,
        });
        break;
      // case 'buy_now':
      // case 'remove_from_cart':


      case 'object_click':
        data = Object.assign(data, {
          page_type: Config.get('temp.page_type'),
        });
        break;

      case 'watch':
        if (itemId) {
          data = Object.assign(data, {
            watch_payload: this.getCollectedData(itemId, event)?.watch_payload || [],
          });
          this.clearCollectedData(itemId, event);
        }
        break;
      case 'heartbeat':
        if (itemId) {
          // this.clearCollectedData(itemId, event);
        }
        console.trace('heartbeat', event_extra_data);
        break;
    }

    return data;
  }

  getCollectedData(itemId, eventName) {
    return (this.collectData[itemId] && this.collectData[itemId][eventName]) || {};
  }

  clearCollectedData(itemId, eventName) {
    if (this.collectData[itemId]) {
      delete this.collectData[itemId][eventName];
    }
  }

  collect(itemId, eventName, playerEvent, eventData) {
    this.collectData[itemId] = this.collectData[itemId] || {};
    if (eventName === 'watch') {
      this.collectData[itemId][eventName] = this.collectData[itemId][eventName] || {
        watch_payload: [],
        seeking: false,
      };

      let lastSegment = this.collectData[itemId][eventName].watch_payload.length
        ? this.collectData[itemId][eventName].watch_payload[this.collectData[itemId][eventName].watch_payload.length - 1]
        : null;

      if (playerEvent === 'seeking') {
        this.collectData[itemId][eventName].seeking = true;
      }

      if (playerEvent === 'seek') {
        this.collectData[itemId][eventName].seeking = false;
      }

      // console.log('[collect][stats]', itemId, playerEvent, eventData, this.collectData[itemId][eventName]);
      if (this.collectData[itemId][eventName].seeking) {
        return;
      }

      if (playerEvent === 'seek' || !lastSegment) {
        lastSegment = [eventData.position, eventData.position];
        this.collectData[itemId][eventName].watch_payload.push(lastSegment);
      } else {
        lastSegment[1] = eventData.position;
      }
    } else if (eventName === 'heartbeat') {
      if (playerEvent === 'ended' && !this.collectData[itemId][eventName]) {
        return;
      }
      this.collectData[itemId][eventName] = this.collectData[itemId][eventName] || {
        watch_time: 0,
      };
      if (playerEvent === 'ready') {
        this.collectData[itemId][eventName].watch_time = 0;
      } else if (eventData.stream_time > 0) {
        if ((eventData.stream_time >= (this.collectData[itemId][eventName].watch_time + 58)) || playerEvent === 'ended') {
          const streamTime = eventData.stream_time - this.collectData[itemId][eventName].watch_time;
          this.collectData[itemId][eventName].watch_time = eventData.stream_time;
          if (playerEvent === 'ended') {
            this.collectData[itemId][eventName].watch_time = 0;
          } else {
            this.collectData[itemId][eventName].watch_time = eventData.stream_time;
          }
          if (streamTime <= 0) {
            return null;
          }
          return { watch_time: streamTime };
        }
      }
    }
  }

  formatEventDate(item) {
    if (item.itype === 'custom_channel_event') {
      item = item.actualEvent;
    }
    const itype = item.itype;
    const options = {
      object_type: this.getItemType(item),
      gtag: this.getGtagContent(item),
      store_id: 'verb',
      channel_id: item?.creator?.id || (typeof item?.creator === 'string' ? item?.creator : undefined),
    };
    if (itype?.includes('live') || itype?.includes('vod') || itype?.includes('epg_shop_event')) {
      options.media_type = itype.includes('vod') ? 'vod' : 'live';
      options.media_id = item.id;
    } else if (itype === 'shop_product') {
      options.product_id = item.id;
    }else if (itype === 'store') {
      options.itype = itype;
      options.store_id = 'verb';
    } else {
      options.item_id = item.id;
    }
    return options;
  }

  getItemType(item) {
    switch (item.itype) {
      case 'item_mov_episode':
      case 'item_mov_vod':
      case 'item_mov_vod_shoprz':
      case 'item_live':
      case 'item_epg_shop_event':
        return 'media';
      case 'shop_product':
        return 'product';
      case 'screen_shoprz':
      case 'event_host_admin':
        return 'screen';
      case 'section_filters_shoprz':
      case 'section_season':
      case 'category_series_head':
        return 'section'; // ?
      case 'banner_group':
        return 'banner'; // ?
    }
  }

  getGtagContent(item) {
    if (['item_mov_vod', 'item_mov_vod_shoprz'].includes(item.itype)) {
      return {
        content_type: 'VOD',
        vod_name: item.title,
      };
    }

    if (item.hasOwnProperty('standalone')) {
      return {
        content_type: 'Storefront',
        store_id: item.id,
        store_name: item.name,
      };
    }
    
    if (['item_epg_shop_event'].includes(item.itype) && item.is_live) {
      return {
        content_type: 'Live Event',
        live_event_name: item.title,
      };
    }

    if (['item_epg_shop_event'].includes(item.itype) && !item.is_live) {
      return {
        content_type: 'Upcoming Live Event',
        live_event_name: item.title,
      };
    }
  }

  send(event, event_extra_data = {}, options = {}) {
    if (!isActiveFeature('shoprz-analytics')) {
      return;
    }
    let computedExtraData = Object.assign({}, this._getEventData(event, options, event_extra_data), event_extra_data);
    this.analyticModule.send(event, computedExtraData, {
      beacon: !!options.beacon,
      only: options.only || null,
      buffer_timestamp: options.buffer_timestamp || null,
    });
  }
}