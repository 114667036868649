import Vue from 'vue';
import becomeAffiliate from 'src/entities/become-affiliate/routes';

export default [
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/ErrorPage/ErrorPage'),
  },
  {
    path: '/callback/:type/:status',
    name: 'Callbacks',
    component: () => import('@/views/CallbacksPage/Callbacks'), // CallbacksContainer,
  },
  {
    path: '/web/:id',
    name: 'Web',
    component: () => import('@/views/WebPage/Web'),
    meta: {
      hideFooter: true,
    },
  },
  {
    path: '/screen/:id',
    name: 'Page',
    component: () => import('@/views/Page/Page'), // PageContainer,
    meta: {
      seoPage: true,
    },
  },
  {
    path: '/p/:id',
    name: 'ChannelShortPage',
    component: () => import('@/views/Page/Page'), // PageContainer,
    meta: {
      creatorPage: true,
      productPage: true,
    },
  },
  {
    path: '/s/:id',
    name: 'ChannelPage',
    component: () => import('@/views/Page/Page'), // PageContainer,
    meta: {
      creatorPage: true,
      seoPage: true,
      pixelAnalitics: true,
    },
  },
  {
    path: '/s/:id/*',
    name: 'ChannelSharePage',
    component: () => import('@/views/Page/Page'), // PageContainer,
    meta: {
      creatorPage: true,
      seoPage: true,
      pixelAnalitics: true,
    },
  },
  {
    path: '/set',
    name: 'Settings',
    component: () => import('@/views/SettingsPage/Settings'), // SettingsPage,
  },
  {
    path: '/get_section/:id',
    name: 'VideoPageSection',
    component: () => import('@/views/CategoryPage/Category'), // CategoryPage,
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/SearchPage/Search'), // SearchContainer,
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('@/views/LivePage/Live'), // LiveContainer,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('@/views/BlogPage/Blog'), // BlogContainer,
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import('@/views/MyProfile/MyProfile'), // MyProfile,
  },
  {
    path: '/my-profile/:param',
    name: 'MyProfileParam',
    component: () => import('@/views/MyProfile/MyProfile'), // MyProfile,
  },
  /* {
        path: '/coming-soon',
        name: 'ComingSoon',
        component: () => import('@/views/ComingSoon/ComingSoon'), // ComingSoonPage,
      }, */
  {
    path: '/stores',
    name: 'stores',
    component: () => import('@/views/allStoresPage/allStoresPage'), // allStores,
  },
  {
    path: '/stores/:category',
    name: 'store',
    component: () => import('@/views/allStoresPage/allStoresPage'), // allStores,
  },
  {
    path: '/catalog*',
    name: 'CatalogPage',
    component: () => import('@/views/CatalogPage/CatalogPage'), // CatalogPage,
  },
  {
    path: '/wishlist',
    name: 'WishlistPage',
    component: () => import('@/views/WishlistPage/WishlistPage'), // WishlistPage,
  },
  {
    path: '/seller-splash',
    name: 'SellerSplash',
    component: () => import('@/views/SellerSplashPage/SellerSplash'), // SellerSplash,
  },

  ...becomeAffiliate,
];
