<template>
  <responsive-component
    v-observe-visibility="configVisibility"
    :aspect-ratio="aspectRatio"
    :content-class="contentClass"
    :content-style="contentStyle"
    :responsive-main-style="responsiveMainStyle"
  >
    <div v-if="previewMode" class="video-preview">
      <video
        playsinline
        muted
        preload="none"
        :class="imgClass"
        class="responsive-image"
        :style="{ 'background-image': 'url(' + normalizeImg(src, 'thumb', 'HD', 'SD') + ')' }"
      />

      <div v-if="isBanner" class="video-preview__overlay">
        <responsive-video-preview-overlay :item-link="item.link"/>
      </div>
    </div>

    <img
      v-else
      v-bind="$attrs"
      :src="normalizeImg(imageChange, 'thumb', 'HD', 'SD')"
      :style="imgStyle"
      :alt="altText"
      class="responsive-image"
      :class="imgClass"
      loading="eager"
      @error="setError"
      @load="loaded()"
    >
    <template v-slot:additionally>
      <slot/>
    </template>
  </responsive-component>
</template>
<script>
  import normaliseImg from '@/filters/normalizeImg.js';
  import responsiveComponent from './responsive.vue';
  import { isString, isNil } from 'lodash';
  import Loader from '@/components/loader/loader.vue';
  import device from '@/service/device-service';
  import responsiveVideoPreviewOverlay from './responsive-video-preview-overlay';

  export default {
    name: 'UgcImage',
    components: {
      Loader,
      responsiveComponent,
      responsiveVideoPreviewOverlay,
    },
    props: {
      src: {
        required: true,
        validator: value => isNil(value) || isString(value),
      },
      alt: {
        validator: value => isNil(value) || isString(value),
        default: null,
      },
      aspectRatio: {
        type: [String],
        default: '16x9',
        validator: type => {
          // The value should be '16x9', '9x16', ...etc;
          return isString(type) && type.toLowerCase().indexOf('x') > -1;
        },
      },
      resolutionType: {
        type: String,
        default: 'thumb',
        validator: t => {
          return t && ['thumb', 'image'].indexOf(t) > -1;
        },
      },

      forceResolutionType: {
        type: Array,
        default: () => [],
      },

      imgClass: {
        type: String,
        default: null,
      },
      contentClass: {
        type: String,
        default: null,
      },
      contentStyle: {
        type: Object,
        default: () => ({}),
      },

      //https://css-tricks.com/almanac/properties/o/object-fit/
      size: {
        type: String,
        default: 'fill',
        validator(val) {
          let vals = ['fill', 'cover', 'contain', 'none', 'scale-down'];
          return vals.indexOf(val) > -1;
        },
      },
      // https://css-tricks.com/almanac/properties/o/object-position/
      position: {
        type: String,
        default: 'center',
        // validator(val) {
        //   let vals = ['top', 'center', 'bottom'];
        //   return vals.indexOf(val) > -1;
        // },
      },

      responsiveMainStyle: {
        type: String,
        default: '',
      },

      previewMode: {
        type: Boolean,
        default: false,
      },
      isBanner: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        image: null,
        isLoaded: true,
        isError: false,
        isVisible: false,
        wasVisible: false,
      };
    },
    computed: {
      isUsedErrorImage() {
        return null; //this.config.notLoaded;
      },
      isUsedNotSetImage() {
        return null; //this.config.notFound;
      },
      errorImage() {
        return  this.isUsedErrorImage
          ? normaliseImg('error')
          : normaliseImg(this.src);
      },
      unsetImage() {
        return this.isUsedNotSetImage ? normaliseImg(null) : null;
      },
      imgStyle() {
        return {
          'object-fit': this.size,
          'object-position': this.position,
          opacity: this.isLoaded ? 1 : 0.3,
          filter: this.isLoaded ? 'unset' : `blur(5px)`,
        };
      },

      altText() {
        return !this.src
          ? 'Image is not set'
          : this.isError
            ? `Image ${
              process.env.NODE_ENV === 'development' ? this.image : ''
            } not loaded.`
            : this.alt;
      },

      imageChange() {
        if ((!this.isVisible && !this.wasVisible) || !this.isLoaded) {
          return this.imagePlaceholder;
        }
        return this.image;
      },

      configVisibility() {
        return {
          callback: this.changeVisibility,
          intersection: {
            rootMargin: '320px',
          },
        };
      },
      // srcSetText() {
      //   if (this.image && this.src) {
      //     let rpl = (src, txt) => src.replace(`original`, txt).replace('.png', '.jpg');
      //     return `${rpl(this.src, 'ED')} 400w,
      //             ${rpl(this.src, 'SD')} 800w,
      //             ${rpl(this.src, 'HD')} 1200w,
      //     `;
      //   } else {
      //     return null;
      //   }
      // },

      imagePlaceholder() {
        let type = this.src? this.src.indexOf('poster') > -1? [9, 16] : this.src.indexOf('backdrop') > -1? [16, 9] : [1, 1] : this.aspectRatio.toLowerCase().split('x');
        return `data:image/svg+xml;utf8,
        <svg viewBox="0 0 ${type[0]} ${type[1]}" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <rect x="0" y="0" width="${type[0]}" height="${type[1]}" stroke="none" fill="transparent"/>
        </svg>`;
      },
    },
    // mounted() {
    //   this.isVisible = this.isInViewport(this.$el);
    //       },
    // updated() {
    //   this.isVisible = this.isInViewport(this.$el);
    //       },
    watch: {
      src: {
        immediate: true,
        handler: function() {
          this.changeImage();
        },
      },
    },
    methods: {
      loaded() {
        // this.$logger.log(`Image ${this.src} is loaded`);
        this.isLoaded = true;
        this.isError = false;
      },
      setError() {
        // this.$logger.log(`Image ${this.src} has error`);
        this.isLoaded = true;
        this.isError = true;
        this.image = this.errorImage;
      },

      changeVisibility(visible, entry) {
        this.isVisible = visible;
        if (this.isVisible) {
          this.wasVisible = true;
        }
      },

      isInViewport(elem) {
        var bounding = elem.getBoundingClientRect();
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth)
        );
      },

      changeImage() {
        let tablet = device.isAnyMobile() && !device.isMobileSize() &&
          this.forceResolutionType.length > 2
          ? this.forceResolutionType[2]
          : this.forceResolutionType[0];
        let mob =
          this.forceResolutionType && this.forceResolutionType.length < 2
            ? undefined
            : tablet;
        let desktop =
          this.forceResolutionType && this.forceResolutionType.length < 2
            ? undefined
            : this.forceResolutionType[1];
        this.image = this.src
          ? normaliseImg(this.src, this.resolutionType, desktop, mob)
          : this.unsetImage;
        this.isError = false;
        this.isLoaded = !this.image;
      },
    },
  };
</script>

<style lang="scss">
	.responsive-image {
		height: 100%;
		transition: opacity 300ms ease-in, filter 300ms ease-in;
		width: 100%;
	}

	.responsive-loader {
		height: 100%;
		width: 100%;
	}

  .video-preview {
    width: 100%;
    display: flex;

    video {
      background-size: cover;
      border: 0;
      height: 100%;
      object-fit: cover;
      transition: opacity 300ms ease-in, filter 300ms ease-in;
      //width: 100%;
      width: calc(100% - 209px);

      @media(max-width: 600px) {
        width: 100%;
      }
    }
  }
</style>

