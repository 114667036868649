export default function (value) {
  if (value) {
    let div = document.createElement('div')
    div.innerHTML = value
    const text = div.textContent || div.innerText || ''
    return text
  } else {
    return '';
  }
}
