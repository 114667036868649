import { vapp } from 'src/main';


import Config from 'src/service/config';
import { amsClient } from '../../service/ams';
import { clone } from 'lodash';


let state = () => {
  return {
    favorites: {},
    watchLater: {},
    homeScreen: null,
  };
};

let getters = {
  isFavoriteItem(state) {
    return ({ item_id, itype }) => {
      return state.favorites && state.favorites[itype] && state.favorites[itype].indexOf(item_id) !== -1;
    };
  },
  isWatchLaterItem(state) {
    return ({ item_id, itype }) => {
      return state.watchLater && state.watchLater[itype] && state.watchLater[itype].indexOf(item_id) !== -1;
    };
  },
  getHomeScreen(state) {
    return state.homeScreen;
  },
};

let mutations = {
  setFavorites(state, values) {
    state.favorites = values;
  },
  setWatchLater(state, values) {
    state.watchLater = values;
  },
  setHomeScreen(state, screen) {
    state.homeScreen = screen;
  },
};

let actions = {
  onSync({ state, dispatch, getters, commit }, data) {
    let favorites = clone(amsClient.get('stock.favorites'));
    let watchLater = clone(amsClient.get('stock.watchLater'));

    commit('setFavorites', favorites);

    commit('setWatchLater', watchLater);


  },
  clean({ state, dispatch, getters, commit }, data) {
    commit('setFavorites', {});
    commit('setWatchLater', {});
  },
  toggleFavorite({ state, dispatch, getters, commit }, { operation, item_id, itype }) {
    let promise;
    if (operation === 'stop') {
      promise = amsClient.stock.stopFavorite(itype, item_id);
    } else {
      promise = amsClient.stock.startFavorite(itype, item_id);
    }
    promise.then((response) => {
      let favorites = clone(amsClient.get('stock.favorites'));
      commit('setFavorites', favorites);
      // if (vapp.$stats) {
      //   vapp.$stats.send(operation === 'stop' ? 'unfavorite_item' : 'favorite_item', {
      //       item_id: item_id,
      //       itype: itype,
      //     },
      //   );
      // }
    });
  },
  toggleWatchLater({ state, dispatch, getters, commit }, { operation, item_id, itype }) {
    let promise;
    if (operation === 'stop') {
      promise = amsClient.stock.stopWatchLater(itype, item_id);
    } else {
      promise = amsClient.stock.startWatchLater(itype, item_id);
    }
    promise.then((response) => {
      let watchLater = clone(amsClient.get('stock.watchLater'));
      commit('setWatchLater', watchLater);
      /*      if (vapp.$stats) {
              vapp.$stats.send(operation === 'stop' ? 'unfavorite_item' : 'favorite_item', {
                  item_id: item_id,
                  itype: itype,
                },
              );
            }*/
    });
  },
  async fetchHomeScreen({ commit }) {
    const response = await amsClient.callAms('/screen/home/');

    commit('setHomeScreen', response);

    return response;
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
