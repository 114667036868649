let DefaultConfig = {
    version: process.env.VUE_APP_PROJECT_VERSION.toString(),
    app_info: (process.env.VUE_APP_CUSTOMER + '-web').toUpperCase(),
    app_id: 'verb.web',
    api_version: '2.8.2',
    env: 'prod', // env which come from AMS for player and analytics
    amsEntryPoint: '/screen/main/',
    player: {
      // it's default player url, it will be changed when come conf from AMS
      url: location.protocol + '//dev-player.vidnt.com/',
      //    free_view_seconds: process.env.NODE_ENV === 'production' ? 20 : 60 * 5, //20 sec : 5min
      options: {
        width: '100%25',
        height: '100%25',
        showEmbedded: false,
        qualityChange: true,
        fullScreen: true,
        controls: true,
        embedded: true,
        backend: location.host,
        lang: 'eng',
      },
      timeoutChangingItems: 10 * 1000, // ms
    },
    analytics: {
      defaultRelatedMethod: 'default',
    },
    languages: {
      default: 'eng',
      rtl: ['heb'],
      list: {
        eng: 'English',
      },
    },
    auth: {
      ams: {
        public_app_key: 'vimmiCB1D0336',
      },
    },
    deep_link: {
      //   android: 'intent://vimmiapp.ais-vidnt.com/open/{{type}}/?id={{id}}#Intent;scheme=https;package=com.ais.mimo.AISPlay;end',
      link: `${window.location.origin}/sharepoint/open/{{type}}/{{id}}/`, // NOTE: / - have link at seft
      //   ios: 'vimmiapp://open/{{type}}/?id={{id}}'
    },

    /** logger colorize */
    components: {
      MP: {
        color: 'lightgreen',
        debug: true,
        // debug: process.env.NODE_ENV !== 'production' && debug
      },
      MPV: {
        color: 'green',
        debug: true,
        // debug: process.env.NODE_ENV !== 'production' && debug
      },
      AS: {
        color: 'pink',
        debug: true,
      },
    },
    fingerprintOpt: {
      fonts: { extendedJsFonts: true },
      excludes: {
        userAgent: true,
        pixelRatio: true,
        screenResolution: true,
        timezoneOffset: true,
        timezone: true,
        indexedDb: true,
        localStorage: true,
        sessionStorage: true,
        addBehavior: true,
        openDatabase: true,
        cpuClass: true,
        colorDepth: true,
        doNotTrack: true,
        availableScreenResolution: true,
        plugins: true,
        webgl: true,
        canvas: true,
        adBlock: true,
        hasLiedLanguages: true,
        hasLiedResolution: true,
        hasLiedOs: true,
        hasLiedBrowser: true,
        fonts: true,
        fontsFlash: true,
        audio: true,
        enumerateDevices: true,
      },
    },
  }
;

export default DefaultConfig;
