<template>
  <div v-click-outside="onClickOutside" class="catalog" :class="{ 'open': showCatalog }">
    <div class="catalog-container">
      <div
        v-for="(nav, index) of catalogs"
        :key="`${nav.head.title}_${index}`"
        class="catalog-menu"
      >
        <div
          v-if="index"
          class="catalog-back"
          @click="clear({ level: 0 })"
        >
          <img :src="require(`@/assets/icons/icon-home.svg`)" alt="">
          <span>Menu</span>
          <i class="fas fa-chevron-right"/>
        </div>
        <h4 v-if="!$isStandaloneStore" class="catalog-title">
          <i v-if="index" class="fas fa-chevron-left" @click="clear({ level: nav.head.level })"/>
          {{ index === 0 ? 'Catalog' : nav.head.title }}
        </h4>
        <nav v-if="!$isStandaloneStore" class="catalog-list">
          <a v-for="(item, key) in nav.menu" :key="item.id">
            <template v-if="item.n_products">
              <span class="desktop-link" @mouseover="onCheckNewItems(item, key)" @click="goToCatalog(item, key)">
                {{ key }}
                <i v-if="!item.is_empty" class="fas fa-chevron-right"/>
              </span>
              <span class="mobile-link" @click="onCheckNewItems(item, key, true)">
                {{ key }}
                <i v-if="!item.is_empty" class="fas fa-chevron-right"/>
              </span>
            </template>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
  import vClickOutside from 'v-click-outside'
  import { debounce } from 'lodash';
  import { amsClient } from 'src/service/ams';

  const DEFAULT_LINK = '/shoprzapi/catalog/pc/cats-menu-level-0/';

  export default {
    name: 'Catalog',
    directives: {
      clickOutside: vClickOutside.directive,
    },
    data: function () {
      return {
        catalogs: [],
        showCatalog: false,
      }
    },
    async created() {
      const data = await this.getItems(DEFAULT_LINK);

      this.$bus.$on('ToggleCatalog', (data) => {
        if (!data) {
          this.clear({ level: 0 });
        }
        this.showCatalog = data;
      });
      this.catalogs.push(data);
      this.onCheckNewItems = debounce(
        (item, key, mobile = false) => {
          const { menu } = item;

          this.getItems(menu)
            .then(data => {
              delete data.menu['All'];

              const { head, menu } = data;
              const emptyCheck = Object.keys(menu)
                .filter(menuKey => menu[menuKey].n_products).length || !head.n_products;

              if (!emptyCheck && mobile) {
                this.goToCatalog(item, key);
              }

              return emptyCheck ? data : null;
            })
            .then(navCol => {
              this.clear(item, key);

              if (navCol) {
                this.catalogs = [...this.catalogs, navCol];
              }
            })
            .catch(() => {
              this.clear({ level: 0 });
            });
        },
        300,
      );
    },
    destroyed() {
      this.$bus.$off('ToggleCatalog');
    },
    methods: {
      getItems(link) {
        return amsClient.callAms(link);
      },
      clear(item, key) {
        if (key === 'All') { return; }
        this.catalogs = [...this.catalogs.slice(0, item.level + 1)];
      },
      goToCatalog(link, key) {
        const url = link?.items?.split('/shoprzapi/catalog/p/')[1];
        this.clear({ level: 0 });
        this.$router.push((key === 'All' && !url) ? '/catalog/' : `/catalog/${url}`);
        this.$bus.$emit('ToggleCatalog', false);
        this.$bus.$emit('ToggleNavLinks', false);
      },
      onClickOutside(event) {
        const isCatalogBtn = (event.path || event.composedPath()).includes(document.getElementById('catalog_btn'));

        if (isCatalogBtn || !this.showCatalog) { return; }

        this.$bus.$emit('ToggleCatalog', false);
      },
    },
  }
</script>
<style lang="scss" scoped="true">
  @import 'Catalog';
</style>
