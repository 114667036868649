import truncateSymbolsToNumber from 'src/utils/truncateSymbolsToNumber';

export default (error, vm, info) => {
  // console.warn('[Component Vue]', vm);
  // console.warn('[info Vue]', info);
  // console.dir(error);
  let page = vm && vm.$route && vm.$route.fullPath ? vm.$route.fullPath.replace(/\//gi, '') : 'portal';
  let name = vm && vm._name ? vm._name.replace(/<|>/gi, '') : 'component';
  let errData = {
    error_name: truncateSymbolsToNumber(`Vue|${page}|${name}|${info}`, 250),
    error_id: '1666',
    error_description: truncateSymbolsToNumber(JSON.stringify(error.message) || 'Vue component error', 250),
    source: navigator.userAgent,
  };
  if (process.env.NODE_ENV !== 'development' && vm && vm !== undefined && vm !== null) {
    // if (vm.$ga) {
    //   vm.$ga.exception(JSON.stringify(error));
    // }
    // if (vm.$stats) {
    //   vm.$stats.send('app_error', {}, {
    //     error: {
    //       name: truncateSymbolsToNumber(`Vue|${page}|${name}|${info}`, 250),
    //       errCode: '1666',
    //       message: truncateSymbolsToNumber(JSON.stringify(error.message) || 'Vue component error', 250),
    //       errSource: 'Portal',
    //     },
    //   });
    // }
    if (
      vm.hasOwnProperty('config') &&
      vm.config.hasOwnProperty('errorHandler')
    ) {
      let _oldOnError = vm.config.errorHandler;
      if (typeof _oldOnError === 'function') {
        _oldOnError.call(this, error, vm, info);
      }
    }
  }
  console.error(error);
};
