<template>
  <div
    id="app"
    class="app-container"
    :class="{
      [`float-mode-${floatingMode}`]: !!floatingMode,
      'fullscreen-emulate': fullscreenEmulate,
      'overflow-hidden': canHideFooter,
    }"
  >
    <vue-headful :head="head" :title="currentTitle" />

    <app-header :active-popup="activePopup" @closeProductPopup="closeProductPopup" />

    <main id="app-content" class="content" :class="[extraClass, offMarginTop]">
      <router-view />

      <vod-preview />

      <product-popup
        v-if="productPopup && productItem"
        :item="productItem"
        :options="productPopupOptions"
        @closeProductPopup="closeProductPopup"
      />

      <footer-main v-if="!canHideFooter" />
    </main>

    <auth-block />

    <vod-popup
      v-if="popupVOD && itemVOD"
      :item="itemVOD"
      @closePopupVOD="closePopupVOD"
      @closeProductPopup="closeProductPopup"
      @change:modal="changeModalState"
    />
    <event-streem
      v-if="currentLive"
      :area="currentLive"
      first-event-component
      live-player
      class="event-streem"
      @closePopupVOD="closePopupVOD"
      @closeProductPopup="closeProductPopup"
      @change:modal="changeModalState"
    />

    <!-- <event-streem
      v-if="currenSecondLive"
      :area="currenSecondLive"
      live-player
      class="event-streem"
      @closePopupVOD="closePopupVOD"
      @closeProductPopup="closeProductPopup"
    /> -->

    <iframe-popup
      v-if="fullScreenIframe && fullScreenItem"
      :item="fullScreenItem"
      @closeIframePopup="closeIframePopup"
    />

    <message-popup
      v-if="messageDialog"
      :store-name="itemEvent.creator.name"
      :store-link="itemEvent.creator.slug"
    />

    <system-overload-popup v-if="systemOverloadModal" @close="systemOverloadModal = false" />

    <app-modals @change:modal="changeModalState" />

    <div
      v-show="showFloatingWrapper"
      ref="floatingWrapper"
      class="floating-wrapper"
    />
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import AuthBlock from '@/components/auth-block/auth-block';
  import AppHeader from '@/components/header/AppHeader';
  import ProductPopup from '@/components/product-popup/product-popup';
  import IframePopup from '@/components/iframe-popup/iframe-popup';
  import VodPopup from '@/components/vod-popup/vod-popup';
  import VodPreview from '@/components/common/vod-preview/vod-preview';
  import EventStreem from './components/channel-profile/event-streem';
  import SellerSplash from '@/views/SellerSplashPage/SellerSplash';
  import Config from '@/service/config';
  import { debounce, cloneDeep } from 'lodash';
  import JsonLD from '@/service/jsonld';
  import MessagePopup from './components/message-popup/message-popup';
  import rtm from '@/service/realTimeMessages';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import Auth from '@/service/authService';
  import CheckoutService from '@/store/checkout';
  import { PM, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';
  import AppModals from '@/components/common/modals/app-modals';
  import FooterMain from '@/components/footerMain/footerMain';
  import WishlistService from '@/store/wishlist';
  import Vue from 'vue';
  import { $stats } from './plugins/analytics/vue-analytics-plugin';
  import StockStore from '@/store/stock';
  import SystemOverloadPopup from './components/system-overload-popup/system-overload.vue';

  export default {
    name: 'App',
    components: {
      SystemOverloadPopup,
      AuthBlock,
      AppHeader,
      ProductPopup,
      VodPopup,
      MessagePopup,
      IframePopup,
      VodPreview,
      AppModals,
      FooterMain,
      SellerSplash,
      EventStreem,
    },
    data() {
      return {
        currentTitle: Config.get('title'),
        resizeId: 0,
        RESIZE_DELAY: 300,
        prevSize: 0,
        extraClass: '',
        fullScreenIframe: false,
        fullScreenItem: null,
        fullscreenEmulate: false,
        popupVOD: false,
        itemVOD: null,
        messageDialog: false,
        itemEvent: null,
        head: {},
        productPopup: false,
        productItem: null,
        prevPage: [],
        productPopupOptions: {},
        offMarginTop: '',
        isReadyLive: true,
        isReadySecondLive: true,
        appModalsPopup: false,
        systemOverloadModal: false,
        historyData: {},
      };
    },
    provide() {
      return {
        toggleReadyLive: this.toggleReadyLive,
      };
    },
    computed: {
      ...mapState('popup', [
        'showAccountPopup',
        'floatingMode',
        'showFloatingWrapper',
      ]),
      ...mapGetters('popup', ['screenType', 'isVodFloating', 'isLiveFloating']),

      isFloatingLive() {
        return this.isMiniatureIframe && this.isLiveFloating;
      },

      currentLive() {
        return this.$store.getters['dataAreasLive/currentLive'];
      },
      // currenSecondLive() {
      //   return this.$store.getters['dataAreasLive/currenSecondLive']
      // },
      isGuest() {
        return Auth.isGuest();
      },
      handleResize() {
        return debounce(this.emitEvent, this.RESIZE_DELAY, {
          leading: false,
          trailing: true,
        });
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      activePopup() {
        return this.productPopup || this.fullScreenIframe || this.popupVOD || this.messageDialog || this.appModalsPopup;
      },
      canHideFooter() {
        return !!this.$route.meta?.hideFooter;
      },
    },
    async created() {
      StockStore.fetchHomeScreen();

      rtm.init('lobby').catch((e) => console.error(e));

      Config.set('temp.context', 'deeplink');
      this.prevSize = window.innerWidth;

      // this.$stats.send('app_open');

      this.$bus.$on('handleSpecificClass', (classList) => {
        this.extraClass = classList;
      });

      this.$bus.$on('closeMiniVod', this.closePopupVOD);

      this.$bus.$on('updateTitle', this.setTitle);

      Config.loaded(() => {
        this.head = {
          'meta[name="google-site-verification"]': {
            content: Config.get('google-site-verification_parameter'),
          },
          'meta[property="fb:app_id"]': {
            content: Config.get('auth.facebook.app_id'),
          },
          'meta[name="prerender-status-code"]': { content: '200' },
        };
      });

      window.addEventListener('resize', this.handleResize);
      window.addEventListener('orientationchange', this.handleOriChange);

      JsonLD.add();

      // PM.inst().on(SHOP_METHODS.TOGGLE_FULLSCREEN, (data) => {
      this.$bus.$on('togglePlayerFullscreen', (data) => {
        console.log('togglePlayerFullscreen', data);
        this.fullscreenEmulate = data.emulate && data.state;
      });
    },
    destroyed() {
      this.$bus.$off('setCurrentPage', this.event_setCurrentPage);

      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('orientationchange', this.handleOriChange);
      JsonLD.pop();
      JsonLD.remove();

      rtm.disconnect();
    },
    async mounted() {
      console.time('AREAS');

      this.$store.dispatch('navLinks/getNavLinks'); // loads main screen )))

      this.$bus.$on('removeAppMarginTop', (isMargin) => {
        this.offMarginTop = isMargin;
      });

      this.addSchemaOrg();

      this.$bus.$on('toggleFullScreenIframe', (data = {}) => {
        const { item } = data;

        if (
          (item && !this.fullScreenItem) ||
          (item && item.id && this.fullScreenItem.id !== item.id)
        ) {
          this.fullScreenIframe = true;
          this.fullScreenItem = item;
        } else {
          this.fullScreenIframe = false;
          this.fullScreenItem = null;
        }
      });

      this.$bus.$on('toggleProductPopup', (data = {}) => {
        const { item, options } = data;

        this.productPopupOptions = options || {};
        this.productPopup = !!item;
        this.productItem = item ?? item;

        if (item && !Auth.get('user').guest) {
          amsClient.callAms(`/stk/recent_shop/?d=web&pk=${item.id}`, {
            method: 'post',
          });
        }
      });

      this.$bus.$on('toggleShopCart', (data = {}) => {
        const { item } = data;

        if (item && !this.fullScreenItem) {
          this.fullScreenIframe = true;
          this.fullScreenItem = item;
        } else {
          this.fullScreenIframe = false;
          this.fullScreenItem = null;
        }
      });

      this.$bus.$on('openVodPopup', (item = {}) => {
        this.popupVOD = true;
        this.itemVOD = item;
        this.$store.commit('player/SET_VOD_ITEM', this.itemVOD);
        this.$store.commit('popup/setLoadingIframe', true);
      });

      this.$bus.$on('togglePopupVOD', (data = {}) => {
        const item = cloneDeep(data.item);

        if (this.isMiniatureIframe) {
          if (this.itemVOD?.id === item?.id) {
            return;
          }

          if (this.isLiveFloating) {
            this.$store.commit('dataAreasLive/setCurrentLive', null);
          }

          this.popupVOD = true;
          this.itemVOD = item;
          this.$store.commit('player/SET_VOD_ITEM', this.itemVOD);
          this.$store.commit('popup/setLoadingIframe', true);

          return;
        }

        if (!this.item || (item && item.id && this.itemVOD.id !== item.id)) {
          this.popupVOD = true;
          this.itemVOD = item;
        } else {
          this.popupVOD = false;
          this.itemVOD = null;
        }
      });

      this.$bus.$on('changeItem', () => {
        if (this.isMiniatureIframe) {
          this.$store.commit('popup/setLoadingIframe', true);
        }
      });

      this.$bus.$on('toggleMessagePopup', (data = {}) => {
        const { item } = data;

        if (item) {
          this.messageDialog = true;
          this.itemEvent = item;
        } else {
          this.messageDialog = false;
          this.itemEvent = null;
        }
      });
      this.$bus.$on('showOverloadPopup', (data = {}) => {
        this.systemOverloadModal = true;
      });

      try {
        const t = localStorage;
      } catch (e) {
        this.$bus.$emit('toggleAuthPopup', {
          component: 'message',
          forceCloseBtn: true,
          closeOutside: false,
          force: true,
          message: this.$i18n.t('ACCESS.LOCALSTORAGE_ACCESS_DENIED'),
          actions: {
            close: this.$i18n.t('OK'),
          },
        });
      }

      this.$bus.$on('setCurrentPage', this.event_setCurrentPage);
      this.$bus.$on('closeProductPopup', this.closeProductPopup);

      this.handleCheckoutSync();
      this.handleShopifyPostMessages();
      this.handleShopLayoutMessages();

      Vue.prototype.$floatingWrapperRef = this.$refs.floatingWrapper;
    },
    methods: {
      ...mapActions({
        setTemplate: 'chachedPreviewMedia/setPlayTemplate',
      }),
      handleOriChange() {
        this.$bus.$emit('orientationChangeWindow');
      },
      changeModalState(value) {
        this.appModalsPopup = value;
      },
      emitEvent(event) {
        let curr = window.innerWidth;
        if (curr !== this.prevSize) {
          /* this.$logger.log(
            `Emit event resize from ${this.prevSize}px to ${curr}px `,
            event,
          ); */
          this.prevSize = curr;
          this.$bus.$emit('resizeWindow', event);
        }
      },
      addSchemaOrg() {
        // TODO: add ais info
        let data = {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          // url: 'https://protvplus.ro/portal/',
          logo:
            location.origin +
            '/portal/static/favicon/' +
            process.env.VUE_APP_CUSTOMER +
            '/favicon.png',
          contactPoint: [
            {
              // '@type': 'ContactPoint',
              // telephone: '+4021-9454',
              // contactType: 'customer service'
            },
          ],
        };
        JsonLD.push(data);
      },
      closeProductPopup() {
        this.productPopup = false;
        this.productItem = null;
      },
      closeIframePopup() {
        this.fullScreenIframe = false;
        this.fullScreenItem = null;
      },
      closePopupVOD() {
        this.popupVOD = false;
        this.itemVOD = null;
        this.$store.commit('player/SET_VOD_ITEM', this.itemVOD);

        // this.$bus.$emit('closePopupVOD');
      },
      setTitle(title) {
        this.currentTitle = null;
        this.$nextTick(() => {
          this.currentTitle = title;
        });
      },
      handleCheckoutSync() {
        CheckoutService.init();

        this.$bus.$on('amsLogged', async () => {
          this.setTemplate();

          WishlistService.getWishlist();
          WishlistService.getVideoWishlist();

          const currentUser = Auth.get('user');

          if (currentUser.guest) {
            await CheckoutService.useGuestCheckoutId();
          } else {
            this.$bus.$emit('toggleAuthPopup');

            const addToWishlist = localStorage.getItem('addToWishlist');

            if (addToWishlist) {
              WishlistService.addToWishlist(addToWishlist);
              localStorage.removeItem('addToWishlist');
            }

            const needUpdateUserCheckoutId = await CheckoutService.useAuthUserCheckoutId();

            if (needUpdateUserCheckoutId) {
              await amsClient.callAms('/shopify/token/', {
                query: { checkout_id: CheckoutService.getCheckoutId() },
              });
            }

            await CheckoutService.mergeCarts();
          }

          CheckoutService.loadCurrentCheckoutCartItems();
        });

        this.$bus.$on('logout', () => {
          CheckoutService.reset();

          document.cookie = encodeURIComponent('_secure_session_id') + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        });
      },
      handleShopifyPostMessages() {
        PM.inst().on('shopify:persistent', async (data) => {
          console.log('[Portal] SHOPIFY_EVENT', data);
          const currentCartItemsCount = CheckoutService.getCartItemsCount();

          if (data?.count !== undefined) {
            CheckoutService.setCartItemsCount(+data.count);
          }

          const { chat_user, picture } = Auth.get('user');

          switch (data.event) {
            case 'getAccountSettings':
              if (!chat_user) {
                return;
              }

              this.sendAccountPostMessage({
                chatNickame: chat_user.name,
                poster: picture || null,
              });

              break;

            case 'saveAccountSettings':
              if (!chat_user) {
                return;
              }

              const chatNickame = data.data.chatNickame;

              await this.updateUserChatName(chatNickame);

              this.sendAccountPostMessage({
                chatNickame,
                poster: picture || null,
              });

              break;

            case 'getCheckoutPageUrl':
              const path = data.data.path;

              const domain = amsClient.get('conf.portal.marketplace.shopify.shopify_api_endpoint');
              const currentUser = Auth.get('user');
              let url = null;

              console.log('path', domain, path);

              let currentCheckout = null;

              try {
                currentCheckout = await CheckoutService.getCheckout();
              } catch {
              }
              if (currentCheckout) {
                // const checkout_id = (new URL(currentCheckout.webUrl)).pathname.split('/').slice(-1)[0]; // todo:
                $stats.send('checkout_clicked', {
                  checkout_id: CheckoutService.getCheckoutId() || CheckoutService.getGuestCheckoutId(),
                  channel_id: this.productItem?.creator?.id,
                  store_id: 'verb',
                });
              }
              console.log('currentCheckout', currentCheckout, CheckoutService.getCheckoutId() || CheckoutService.getGuestCheckoutId());

              if (currentUser.guest) {
                if (currentCheckout && currentCheckout.webUrl) {
                  url = currentCheckout.webUrl;
                } else {
                  url = `${domain}/${path}`;
                }
              } else {
                let checkoutPath = `/${path}`;

                if (currentCheckout && currentCheckout.webUrl) {
                  const parsedUrl = new URL(currentCheckout.webUrl);

                  checkoutPath = `${parsedUrl.pathname}${parsedUrl.search}`;
                }

                console.log('checkoutPath', checkoutPath);

                const multipassTokenResponse = await amsClient.callAms('/shopify/token/', {
                  query: { redirect_path: checkoutPath },
                });

                url = `${domain}/account/login/multipass/${multipassTokenResponse.head.multify_token}`;
                // url = `${domain}/${path}`
              }

              /* if (window._refersion && window._rfsn) {
                const pathParts = path.split('/');
                const checkoutToken = pathParts[pathParts.length - 1];

                _refersion(function() {
                  _rfsn._addCart(checkoutToken);
                });
              } */

              console.log('mutipass url', url);

              const target = this.$shopingCartIframeRef && this.$shopingCartIframeRef.contentWindow
                ? this.$shopingCartIframeRef
                : this.$productIframeRef;

              PM.inst().send({
                target,
                type: 'portal',
                data: {
                  event: 'checkoutPageUrl',
                  error: null,
                  data: {
                    url,
                  },
                },
              });

              // window.open(url, '_blank');

              break;
            case 'addToCart':
              $stats.send('add_to_cart', {
                product_id: this.productItem.id,
                channel_id: this.productItem?.creator?.id,
                store_id: 'verb',
                // variant_id: null, // todo:
                quantity: +data.count - currentCartItemsCount,
              });
              break;
            case 'removeFromCart':
              $stats.send('remove_from_cart', {
                // product_id: null, // todo:
                // channel_id: null, // todo:
                // variant_id: null, // todo:
                quantity: currentCartItemsCount - +data.count,
              });
              break;
            // case 'BUY': // TBD
            //   $stats.send('buy_now', {
            //     product_id: null, // todo:
            //     channel_id: null, // todo:
            //     variant_id: null, // todo:
            //     quantity: currentCartItemsCount - +data.count,
            //   });
            //   break;
            // case 'checkout_finished': // TBD
            //   let currentCheckout = null;
            //
            //   try {
            //     currentCheckout = await CheckoutService.getCheckout();
            //   } catch {
            //   }
            //   $stats.send('checkout_finished', {
            //     checkout_id: currentCheckout.id,
            //   });
            //   break;
          }
        });
      },
      sendAccountPostMessage(data) {
        PM.inst().send({
          target: this.$accountIframeRef,
          type: 'portal',
          data: {
            event: 'accountSettings',
            error: null,
            data,
            key: 'accountSettings',
          },
        });
      },
      async updateUserChatName(name) {
        const chatUser = Auth.get('user.chat_user');

        if (!chatUser) {
          return;
        }

        await amsClient.account.update({ name });
        amsClient.set('user.chat_user.name', name);
      },
      handleShopLayoutMessages() {
        PM.inst().on(SHOP_METHODS.UPDATE_CHAT_NICK, async (data) => {
          await this.updateUserChatName(data.name);
        });
      },
      event_setCurrentPage(path) {
        if (path) {
          if (!this.prevPage.length) {
            this.prevPage.push(this.$route.fullPath);
          }

          if (!this.prevPage.includes(path)) {
            this.prevPage.push(path);
          } else {
            const storeMatch = path.match(/^\/s\/(.*?)\//gi);

            if (storeMatch) {
              this.prevPage.unshift(storeMatch[0]);
            }
          }
        } else if (!path && this.prevPage.length) {
          const lastPage = this.prevPage.pop();
          const backPage = this.prevPage.pop();
          const storeMatch = lastPage.match(/^\/s\/(.*?)\//gi);

          if (backPage) {
            path = backPage;
          } else if (storeMatch) {
            path = storeMatch[0];
          } else {
            path = lastPage;
          }
        }

        const historyPath =
          this.$location +
          (process.env.NODE_ENV === 'development' ? '/#' : '') +
          (path || '');

        if (this.historyData.lastPage) {
          this.historyData.beforeLastPage = this.historyData.lastPage;
        }

        this.historyData.lastPage = historyPath;

        history.pushState(this.historyData, null, historyPath);
      },
      toggleReadyLive(value = false) {
        this.isReadyLive = value;
      },
    },
  };
</script>

<style lang="scss">
  // NOTE: ORDER IS IMPORTANT
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
    font-family: 'Montserrat';
  }

  @import '~swiper/css/swiper.css';

  @import 'src/style/custom_bootstrap';
  @import './style/animate';

  @import './style/icons/fontawesome-all.css';
  @import 'src/style/scroll';
  @import 'src/style/shared';
  @import 'src/style/app';
  @import 'src/style/fix';
  @import 'src/style/modals';

  /* * {
    padding: 0;
    margin: 0;
  }

  * {
    background-repeat: no-repeat;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  } */

  .floating-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
  }

  .event-streem {
    position: absolute;
    top: 250px;
    z-index: 10;
    // left: 15px;
    // width: 72vw;
    @media screen and (max-width: 1200px) {
      top: 15px;
    }
  }
</style>
