import Vue from 'vue';

import NormalizeImg from './normalizeImg';
import Duration from './duration';
import FormatDate from './formatDate';
import NormalizePrice from './normalizePrice';
// import TruncateOnWord from './truncateOnWord';
import StripHTML from './stripHtml';
import RenderBackgroundImage from './renderBackgroundImage';
import LinkNormalisation from './linkNormalisation';
import ContentLanguage from './contentLanguage';
import ViewsNormalise from './viewsNormalise';
export let normalizeImg = Vue.filter('normalizeImg', NormalizeImg)
export let renderBackgroundImage = Vue.filter('renderBackgroundImage', RenderBackgroundImage);
export let stripHTML = Vue.filter('stripHTML', StripHTML);
export let linkNormalisation = Vue.filter('linkNormalisation', LinkNormalisation);
export let viewsNormalise = Vue.filter('viewsNormalise', ViewsNormalise);
// export let truncateOnWord = Vue.filter('truncateOnWord', TruncateOnWord);
export let formatDate = Vue.filter('formatDate', FormatDate);
export let duration = Vue.filter('duration', Duration);
export let contentLanguage = Vue.filter('contentLanguage', ContentLanguage);
export let normalizePrice = Vue.filter('normalizePrice', NormalizePrice);

export default {
  duration: duration,
  viewsNormalise: viewsNormalise,
  linkNormalisation: linkNormalisation,
  contentLanguage: contentLanguage,
  // truncateOnWord: truncateOnWord,
  normalizeImg: normalizeImg,
  formatDate: formatDate,
  stripHTML: stripHTML,
  renderBackgroundImage: renderBackgroundImage,
  normalizePrice: normalizePrice,
}
