<template>
  <div class="media-related-container">
    <h2 class="media-related-title default-title  text-uppercase d-lg-none my-0">
      <span v-t="{path: 'VIDEO_COMPONENT.RELATED_LIST'}"/>
    </h2>
    <div class="media-related-list">
      <media-related-block
        v-for="(item, index) in items"
        :key="index"
        :index="index"
        :item="item"
        :event-identifier="eventIdentifier"
        :not-reload-page="notReloadPage"
      />
    </div>
  </div>
</template>

<script>
  import MediaRelatedBlock from './media-related-block';

  //  import eventBus from '../eventBus';

  export default {
    name: 'MediaPlayerRelated',
    components: {
      MediaRelatedBlock,
    },
    props: {
      eventIdentifier: {
        type: [String,Number],
      },
      videoItems: {
        type: Array,
      },
      current: {
        type: Object,
      },
      notReloadPage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        items: [],
      }
    },
    watch: {
      videoItems: function(items) {
        this.processData()
      },
    },

    mounted() {
      this.processData()
    },
    methods: {
      processData() {
        let tempItems = this.videoItems; //.slice(0, this.limitCount());
        // Populate some vm data here for now
        if (this.videoItems.length) {
          tempItems.forEach((el) => {
            el.active = false;
            el.next = false;
          });
          let pos = tempItems.findIndex(el => (this.current && this.current.id)? el.id === this.current.id : false);
          if (pos > -1){
            let next_pos = pos + 1 < this.videoItems.length ? pos + 1 : 0; 
            tempItems[pos].active = true;
            tempItems[next_pos].next = true;
          }
        }
        this.items = Object.assign([], tempItems);
      },
      //      limitCount () {
      //        let limitCount = this.limit;
      //        return limitCount
      //      }
    },
  }
</script>

<style lang="scss">
  @import './media-related';
</style>
