import { amsClient } from '@/service/ams';

let state = () => {
  return {
    followChannels: [],
    followedStores: [],
  };
};

let getters = {
  isChannelFollowed(state) {
    return (creator) => {
      return state.followChannels && state.followChannels.indexOf(creator) !== -1;
    };
  },

  followedStores(state) {
    return [...state.followedStores];
  },
};

let mutations = {
  _setFollowedChannels(state, values) {
    state.followChannels = values;
  },

  _setFollowedStores(state, values) {
    state.followedStores = values;
  },
};

let actions = {
  onSync({ state, dispatch, getters, commit }) {
    amsClient.callAms('/shoprzapi/user-interaction/subscribe/', {
      cache: false,
    }).then((resp) => {
      commit('_setFollowedChannels', resp.info.creators || []);
    });
  },

  clean({ state, dispatch, getters, commit }, data) {
    commit('_setFollowedChannels', []);
  },

  toggleFollow({ state, dispatch, getters, commit }, creator) {
    let channels = [...state.followChannels];
    let pos = channels.indexOf(creator);
    let op = pos !== -1 ? 'stop' : 'start';
    amsClient.callAms('/shoprzapi/user-interaction/subscribe/', {
      cache: false,
      query: {
        op: op, creator: creator,
      },
    }).then((res) => {
      if (op === 'start') {
        channels.push(creator);
      } else {
        channels.splice(pos, 1);
      }
      commit('_setFollowedChannels', channels);
    });
  },

  setFollowedStores({commit}, items) {
    commit('_setFollowedStores', items);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
