import { localStore } from 'vimmi-web-utils/esm/localStore';
import { vapp } from '@/main';
import { isActiveFeature } from 'src/plugins/feature-toggle';
import { logger } from 'src/service/logger';
import { amsClient } from '../ams';
import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

class AuthService {
  constructor() {
    this.$logger = logger();

    amsClient.on('auth_state_change', (event, eventData) => {
      this.$logger.log('[amsClient][auth_state_change]', eventData);

      switch (eventData.type) {
        case 'logged':
          if (amsClient.get('user.guest')) {
            vapp.$bus.$emit('changedUserState', eventData);
          }

          // console.log('amsLogged');

          vapp.$bus.$emit('amsLogged', eventData);

          setTimeout(() => {
            vapp.$bus.$emit('amsLogged', eventData);
          }, 1000);

          setTimeout(() => {
            $stats.send('session_start');
          }, 100);
          // the application should displays user as logged
          break;
        case 'inactive':
          // that means that user comes from AMS as inactive, so need to logout
          break;
        case 'logout':
          this.sessionStart = false;
          // the user log out
          this.afterLogOut();
          break;
      }
    });

    amsClient.on('before_auth_state_change', (event, eventData) => {
      // this.$logger.log('[amsClient][before_auth_state_change]', eventData);
      switch (eventData.type) {
        case 'logged':
          vapp.$bus.$emit('amsBeforeLogged', eventData);
          break;
        case 'inactive':

          break;
        case 'logout':
          vapp.$bus.$emit('amsBeforeLogout', eventData);
          break;
      }
    });

    /** when AMS sync complete */
    amsClient.on('sync', (event, eventData) => {
      vapp.$bus.$emit('sync', eventData);
    });
  }


  isGuest() {
    return !!amsClient.get('user.guest');
  }

  isAuth() {
    return amsClient.isAuth();
  }

  getDeviceRestriction() {
    return amsClient.get('deviceRestrictions');
  }

  saveCurrentPath(initPath = '') {
    const path = initPath || vapp.$router.currentRoute.fullPath;
    localStore.set('auth-redirect-url', path);
  }

  getPortalPrevPath() {
    return localStore.get('auth-redirect-url');
  }

  loginByAMS(credentials, rememberMe = false) {
    return new Promise((resolve, reject) => {
      let handler = function() {
        vapp.$nextTick(() => {
          vapp.$bus.$emit('changedUserState', { type: 'logged' });
          amsClient.off('sync', handler);

          /* if (vapp.$route.path.indexOf('home_web') !== -1) {
            vapp.$router.push({
              path: '/',
            });
          } */

          resolve(amsClient.get('user'));
        });
      };
      amsClient.on('sync', handler);
      amsClient.account.login(credentials.user, credentials.pass)
        .catch((error) => {
          console.log('debug loginAMS catch');
          reject(error);
        });
    });
  }

  async loginByGoogle(code) {
    let handler = function() {
      vapp.$nextTick(() => {

        vapp.$bus.$emit('changedUserState', { type: 'logged' });

        amsClient.off('sync', handler);
      });
    };
    amsClient.on('sync', handler);

    return amsClient.account.googleLogin(code);
  }

  async loginByFacebook(code) {
    let handler = function() {
      vapp.$nextTick(() => {

        vapp.$bus.$emit('changedUserState', { type: 'logged' });

        amsClient.off('sync', handler);
      });
    };
    amsClient.on('sync', handler);

    return amsClient.account.facebookLogin(code);
  }

  confirmRegister(pk, code) {
    return amsClient.account.registerConfirm(pk, code);
  }

  register(authData) {
    return amsClient.account.register(authData);
  }

  logOut(beforeLogin = false) {
    return amsClient.account.logout().then((response) => {
      if (isActiveFeature('guest-users') && !beforeLogin) {
        let handler = function() {
          vapp.$nextTick(() => {
            vapp.$bus.$emit('changedUserState', { type: 'logged', afterLogout: true });

            amsClient.off('sync', handler);
          });
        };

        amsClient.on('sync', handler);
        amsClient.account.guestLogin().then(() => {
        });
      }
    });
  }

  afterLogOut() {
    vapp.$nextTick(() => {
      vapp.$bus.$emit('changedUserState', { type: 'logout' });
    });
  }

  get(key) {
    return amsClient.get(key);
  }
}

export default new AuthService();
