import { PM, SHOP_EVENTS, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';
import { screenMode } from 'vimmi-web-utils/cjs/screenMode';
import PopupService from '@/service/popup';
import WishlistService from '@/store/wishlist';
import { mapGetters, mapState } from 'vuex';
import { SET_MINIMAZE } from './events.const';
import { amsClient } from '@/service/ams';
import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
import device from '@/service/device-service.js';

export const EmbeddedMode = {
  data() {
    return {
      pmSubscriptions: {
        [SHOP_EVENTS.MODE_SWITCHED]: this.onPlayerModeChanged,
        [SHOP_METHODS.OPEN_PRODUCT_IFRAME]: this.handleOpenProductIframe,
        [SHOP_METHODS.TOGGLE_LIKE]: this.handleLike,
        [SHOP_METHODS.TOGGLE_MUTE]: this.handleMute,
        // [SHOP_METHODS.CLOSE_FLOATING]: this.closeFloatMode,
        // [SHOP_METHODS.SWITCH_MODE]: this.switchMode,
        [SHOP_EVENTS.ITEM_READY]: this.onItemReady,
      },
      pmSubscribed: [],
      screenMode: screenMode(),
      hasFloatMode: false,
      canMoveFloating: false,
      offset: null,
      isReady: false,
      isReadyPM: false,
      eventTrigger: false,
    };
  },
  computed: {
    ...mapState('popup', ['floatingMode', 'screenType']),
    ...mapGetters({
      getVideosList: 'wishlist/getVideosList',
      miniPlayerFromProduct: 'popup/miniPlayerFromProduct',
    }),
    isMobile() {
      return device.isAnyMobile() || device.isMobileSize();
    },
    isMiniatureIframe() {
      return this.screenType === 'miniature';
    },
    floatPlayerRef() {
      return this.floatingMode === 'vod'
        ? this.$floatingVodRef
        : this.$floatingLiveRef;
    },
    currentLive() {
      return this.$store.getters['dataAreasLive/currentLive'];
    },
    currenSecondLive() {
      return this.$store.getters['dataAreasLive/currenSecondLive'];
    },
    isFirstEventComponentFloating() {
      return this.$store.getters['player/isFirstEventComponentFloating'];
    },
    isSecondEventComponentFloating() {
      return this.$store.getters['player/isSecondEventComponentFloating'];
    },
    pageForCurrentLive() {
      return this.$store.getters['player/pageForCurrentLive'];
    },
    pageForSecondLive() {
      return this.$store.getters['player/pageForSecondLive'];
    },
    playerCoords() {
      return this.$store.getters['player/playerCoords'];
    },
    isLiveData() {
      return this.$store.getters['player/isLiveData'];
    },
    isMuted() {
      return this.$store.getters['player/muted'];
    },
  },
  watch: {
    eventTrigger(value) {
      if (value) {
        this.$bus.$on('handleCloseFloatPlayer', this.handleCloseFloatMode);
        this.eventTrigger = false;
      }
    },
  },
  inject: ['toggleReadyLive'],
  created() {
    this.mountBusEvents();
    window.parent.addEventListener('orientationchange', this.onOriChanged);
  },
  beforeDestroy() {
    window.parent.removeEventListener('orientationchange', this.onOriChanged);
    this.destroyBusEvents();
    this.unSubscribePlayerEvents();
    // this.destroyPMEvents();
  },
  methods: {
    subscribeToPlayerEvents(subscriptions) {
      Object.keys(subscriptions).forEach(key => {
        this.pmSubscribed.push(PM.inst().target(this.eventIdentifier + '_video_player').on(key, subscriptions[key]));
      });
    },
    unSubscribePlayerEvents() {
      PM.inst().off(this.pmSubscribed);
      this.pmSubscribed = [];
    },

    mountBusEvents() {
      this.$bus.$on(this.eventIdentifier + '_videoComponentReady', this.event_videoComponentReady);
      this.$bus.$on('setMiniplayer', this.handleRoutingFloating);
      this.$bus.$on('handleCloseFloatPlayer', this.handleCloseFloatMode);
      this.$bus.$on('setVodFLoating', this.handleFloatVodPlayer);
      this.$bus.$on('setLiveFLoating', this.handleLiveFloating);
      this.$bus.$on('clearLiveFloating', this.clearStoreForLivePlayer);
      this.$bus.$on(
        'handleCloseFloatingPMLive',
        this.handleCloseFloatingPMLive,
      );
    },
    destroyBusEvents() {
      this.$bus.$off(this.eventIdentifier + '_videoComponentReady', this.event_videoComponentReady);
      this.$bus.$off('setVodFLoating');
      this.$bus.$off('setLiveFLoating');
    },
    event_videoComponentReady() {
      this.subscribeToPlayerEvents(this.pmSubscriptions);
    },
    handleOpenProductIframe(data = {}) {
      const source = data.ref?.source === 'vod' ? 'vod' : 'live';
      if (data.item) {
        this.setProductPopup(data);
      } else if (data.event && data.event === SET_MINIMAZE) {
        document.documentElement.style.overflow = 'visible';
      }
      if (data.item || data.event === SET_MINIMAZE) {
        this.setFloatModePlayer(source);
        this.toggleFloatModePlayer(source);
      }
      if (data.ref.isStream) {
        this.$bus.$emit('closeMiniVod');
      }
      // this.$store.commit('player/SET_IS_FROM_VIDEO', true);
    },
    onPlayerModeChanged(data) {
      if (data.mode === 'f' && this.isMiniatureIframe) {
        PopupService.setScreenType('miniature');
      }
    },
    async handleLike(data) {
      if (Object.keys(this.getVideosList).includes(data.id)) {
        await WishlistService.removeFromVideosWishlist(data.id);
      } else {
        await WishlistService.addToVideoWishlist(data.id);
      }
      PM.inst().target(this.eventIdentifier + '_video_player').send({
        type: SHOP_EVENTS.FAVORITE_TOGGLED,
        data: {
          isWishList: Object.keys(this.getVideosList).includes(data.id),
        },
      });
    },
    handleMute(data) {
      this.$store.commit('player/SET_MUTED', data.muted);
    },
  /*  switchMode(data) {
      console.log(22222222, data);
      if (data.mode === 'l') {
        this.handleCloseFloatMode();
        this.$bus.$emit('closeMiniVod');
      }
    },*/
    onItemReady(data) {
      let playerId;
      if (data.isVod) {
        if (!this.isGuest && !this.isReady) {
          amsClient.callAms(`/stk/recent/?d=web&pk=${data.item.id}`, {
            method: 'post',
          });
        }
        this.toggleReadyLive(true);
        playerId = memoryStore.get('vod-player-identifier');
      } else {
        playerId = memoryStore.get('live-player-identifier');
      }
      this.isReady = true;
      if (this.isMiniatureIframe) {
        this.$store.commit('popup/setLoadingIframe', false);

        PM.inst().target((playerId || this.eventIdentifier) + '_video_player').send({
          type: SHOP_METHODS.SWITCH_MODE,
          data: {
            mode: 'f',
          },
        });
        if (data.isVod && this.playerCoords) {
          const vodPalayer = document.querySelector('.shop_screen-miniature');
          vodPalayer.style.top = `${this.playerCoords.y}px`;
          vodPalayer.style.left = `${this.playerCoords.x}px`;
        }
      }
      if (this.isMuted) {
        PM.inst().target(this.eventIdentifier + '_video_player').send({
          type: SHOP_METHODS.TOGGLE_MUTE,
          data: {
            muted: this.isMuted,
          },
        });
      }
    },
    handleCloseFloatMode(id = null) {
      this.$bus.$off('handleCloseFloatPlayer', this.handleCloseFloatMode);
      this.eventTrigger = true;
      this.floatPlayerRef?.removeAttribute('style');
      this.hasFloatMode = false;
      PopupService.setFloatingMode(null);
      PopupService.setScreenType('full');
      if (!this.isMiniatureIframe || this.miniPlayerFromProduct) {
        this.$store.commit('popup/setMiniPlayerFromProduct', false);
        PM.inst().target((id || this.eventIdentifier) + '_video_player').send({
          type: SHOP_METHODS.SWITCH_MODE,
          data: {
            mode: 'l',
          },
        });
      }
    },
    toggleFloatModePlayer(source) {
      if (source === 'vod') return;
      if (
        this.currentLive &&
        !this.currenSecondLive &&
        !this.isFirstEventComponentFloating
      ) {
        this.handleSetFirstEventComponentFloating();
        return;
      }
      if (this.currenSecondLive && !this.isSecondEventComponentFloating) {
        this.handleSetSecondEventComponentFloating();
        this.$store.commit('dataAreasLive/setCurrentLive', null);
      }
    },
    setFloatModePlayer(source = 'vod') {
      PopupService.setScreenType('miniature');
      PopupService.setFloatingMode(source);
      this.hasFloatMode = true;
    },
    handleFloatVodPlayer() {
      this.$store.commit('dataAreasLive/setPlayingLive', false);
      PopupService.setFloatingMode('vod');
    },
    handleLiveFloating(source = 'live') {
      if (source === 'vod') {
        this.clearStoreForLivePlayer();
        return;
      }
      if (this.currentLive && !this.currenSecondLive) {
        this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
          name: 'isFirstEventComponentFloating',
          value: true,
        });
        return;
      }
      // if (this.currenSecondLive && this.currentLive) {
      //   if (this.isSecondEventComponentFloating) {
      //     this.$store.commit('dataAreasLive/setCurrenSecondLive', null);
      //     this.handleSetFirstEventComponentFloating();
      //     return;
      //   }
      //   if (this.isFirstEventComponentFloating) {
      //     this.$store.commit('dataAreasLive/setCurrentLive', null);
      //     this.handleSetSecondEventComponentFloating();
      //     return;
      //   }
      // }
    },
    clearStoreForLivePlayer() {
      this.$store.commit('dataAreasLive/setCurrentLive', null);
      this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
    },
    handleSetFirstEventComponentFloating() {
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isFirstEventComponentFloating',
        value: true,
      });
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isSecondEventComponentFloating',
        value: false,
      });
    },
    handleSetSecondEventComponentFloating() {
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isFirstEventComponentFloating',
        value: false,
      });
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isSecondEventComponentFloating',
        value: true,
      });
    },
    handleRoutingFloating() {
      PM.inst().target(this.eventIdentifier + '_video_player').send({
        type: 'IS_ROUTING',
      });
    },
    handleCloseFloatingPMLive() {
      const liveId = memoryStore.get('live-player-identifier');
      PM.inst()
        .target(liveId + '_video_player')
        .send({
          target: this.getCurrentFrame(liveId + '_video_player'),
          type: SHOP_METHODS.CLOSE_FLOATING,
        });
      document.documentElement.style.overflow = 'visible';
    },
    onOriChanged(event) {
      setTimeout(() => {
        this.screenMode = screenMode();

        const width = event.target.innerWidth;
        const height = event.target.innerHeight;

        const isLandScape = width > height;


        if (isLandScape && this.isMobile) {
          PM.inst().target(this.eventIdentifier + '_video_player').send({
            type: SHOP_EVENTS.ORIENTATION_CHANGE,
            data: {
              orientation: 'landscape',
            },
          });
        }

        if (this.isMobile && !isLandScape) {
          PM.inst().target(this.eventIdentifier + '_video_player').send({
            type: SHOP_EVENTS.ORIENTATION_CHANGE,
            data: {
              orientation: 'vertical',
            },
          });
        }
      }, 200);
    },
    setProductPopup(data) {
      data.item.link = data.link;
      data.item.ref = data.ref;
      this.$bus.$emit('toggleProductPopup', { item: data.item });
      this.$store.commit('popup/setMiniPlayerFromProduct', true);
    },
  },
};
