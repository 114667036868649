<template>
  <div ref="popupWrapper" class="system-overload">
    <div ref="popup" class="system-overload__container">
      <h1 class="system-overload__title">
        We’re currently experiencing a high volume of requests. <br>
        Please wait a few minutes and try again. Thank you for your patience.
      </h1>
      <div class="system-overload__actions">
        <button class="system-overload__close" @click="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SystemOverloadPopup',
    props: {},
    methods: {
      closePopup() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "./system-overload";
</style>
