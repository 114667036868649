import Vue from 'vue';
import Router from 'vue-router';
import store  from '@/store';
import Auth from '@/service/authService';
import routes from './routes';
Vue.use(Router);

const router = new Router({
  mode: process.env.NODE_ENV !== 'production' ? 'hash' : 'history', // to testing v-account need to use only 'history' mode
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.fbq && window.fbq('track', 'PageView');

  const playingLive = store.getters['dataAreasLive/playingLive'];
  const isLiveFloating = store.getters['popup/isLiveFloating'];
  const routerToLive = store.getters['player/routerToLive'];
  const screenType = store.getters['popup/screenType'];

  if (!playingLive && !isLiveFloating) {
    store.commit('dataAreasLive/setCurrentLive', null);
  }

  if (screenType !== 'miniature' && !routerToLive) {
    Vue.prototype.$bus.$emit('setMiniplayer');

  }

  Vue.prototype.$bus.$emit('toggleFullScreenIframe');

  const needAuth = to.matched.some((record) => record.meta.requiresAuth) && !Auth.isAuth();

  if (needAuth) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
