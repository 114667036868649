export default {
  bind: function (el, binding, vnode) {
    // console.log('Bind');
    el.event = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        
        vnode.context[binding.expression](event);
      }
      // console.log(el, event.target);
    };
    document.body.addEventListener('click', el.event)
  },
  unbind: function (el) {
    // console.log('UnBind');
    document.body.removeEventListener('click', el.event)
  },
}
