export default {
  // Home Page
  HEADER: {
    LOGIN: 'Sign In',
    PROFILE: 'Profile',
    ACCOUNT: 'Account',
    LOGOUT: 'Logout',
    SEARCH: 'Search',
    HOME_BTN: 'Return to Home',
    MENU: 'Menu',
  },

  // Show Page/ Video Page
  VIDEO_COMPONENT: {
    RELATED_LIST: 'Related items',
    NO_RELATED_LIST: 'No related items',
  },

  // Live Page
  EPG: {
    TV_SCHEDULE: 'TV Program Guides',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
  },
  // Error Page
  ERROR: {
    BACK_HOME: 'Go to homepage',
  },

  // Search Page
  SEARCH_PAGE: {
    RESULTS_FOR: 'Results for:',
    NOT_SELECTED: 'Not selected categories to view.',
    NOT_FOUND: 'Not found {name} with keywords:',
    LOAD_MORE: 'Load More',
  },

  // Dashboard
  BLOCK: {
    SETTINGS: 'Settings',
    DIRECTORS: 'Directors',
    BLOCK: {
      MY_ACCOUNT: 'My account',
      MY_PERSONAL_DATA: 'Personal data',
      MY_PREFERENCE: 'My Preferences',
      CONTACT_US: {
        TITLE: 'Contact us',
        FORM: 'Send feedback',
        PHONE: {
          NAME: 'Phone',
          VALUE: '1700-50-41-41',
        },
        SUCCESS_MESSAGE: 'Message successfully sent',
        ERROR_MESSAGE: 'Something went wrong',
        EXPIRATION: 'Expiration date',
        EMAIL: {
          NAME: 'Email',
          VALUE: 'zmaneshkol@NLC.co.il',
        },
      },
      PRICES: {
        TITLE: 'Prices',
        COST: {
          NAME: 'Lectures cost',
          VALUE: '39 Shekels',
        },
      },
      PURCHASES: 'Purchase history',
    },
    TERMS: 'Terms of Use and Privacy',
    LANGUAGE: 'Language',
    AUDIO_LANGUAGE: 'Preferred audio language',
    SAVE: 'Save',
    LENGTH: 'Length:',
    YEAR: 'Year:',
    VIEWS: 'Views:',
    SEASONS: 'Seasons:',
    USER: {
      MY_ACCOUNT: 'My account',
      INTERNET_NUMBER: 'AIS Intenet number',
      CONTACT_NUMBER: 'Contact number',
      EMAIL: 'Email',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      PHONE: 'Phone Number',
      PASSWORD: {
        TITLE: 'Password',
        CHANGE_PASSWORD: 'Change password',
      },
    },
  },

  // Change password popup
  CHANGE_PASSWORD: {
    TITLE: 'Change password',
    OLD_PAS: 'old password',
    NEW_PAS: 'new password',
    REPEAT_PAS: 'repeat',
    BUTTONS: {
      CONFIRM: 'Confirm ',
      CANCEL: 'Cancel ',
    },
    SUCCESS: 'You have successfully changed your password',
    NEW_PASS_DIFF: 'New passwords are different',
    OLD_PASS_INCORRECT: 'Old password is incorrect',
  },

  // Autorization form
  LOGIN: {
    LOGIN_TITLE: 'Log in to get started',
    LOGIN_EMAIL_OR_PHONE: 'Log in with Email or Phone',
    BY_GOOGLE: 'Google',
    BY_APPLE: 'Apple',
    BY_FACEBOOK: 'Facebook',
    BY_EMAIL: 'Log in with Email',
    BY_PHONE: 'Log in with Phone',
    EMAIL: 'Email',
    SING_IN: 'Sign in',
    REGISTER: 'Sign up',
    CANCEL: 'Cancel',
    FORGOT_PASS: 'Forgot your password?',
    WAIT: 'Please wait!',
    FUNGUS: 'Fungus',
    PASSWORD: 'Password',
    REMEMBER_ME: 'Remember me',
    RESEND_CONFIRM: 'Resend confirmation message',
    ACCOUNT_DO_NOT_EXIST: 'Unknown email or password',

    SUCCESSFULLY_SENT: 'Confirmation message successfully sent',
    PLEASE_FILL_IN_LOGIN: 'Please fill in the correct username!',
    PLEASE_FILL_IN_PASSWORD: 'Please fill in the correct password!',
    ACCOUNT_NOT_ACTIVE: 'Your account has not been activated yet. Please check your email.',
    CORRECT_LOGIN: 'Please check email',
    YOUR_PASSWORD_MUST:
      'Your password must be 6-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or email.',
    SUCCESSFULLY_AUTHORIZED: 'You are successfully authorized!',
    AUTHENTICATION_DATA:
      'If you already have an account, you can use the same authentication data.',
    CLICK_LOGIN: {
      ZMAN: 'If you have an existing account, click Sign In.',
      NINE: 'If you have an existing account, click Sign in,',
      VERB: 'If you have an existing account, click Sign in,',
    },

  },
  REGISTER_FORM: {
    REGISTER_TITLE: 'Sign up',
    USERNAME: 'User name',
    USER_FIRST_NAME_INVALID: 'first name invalid',
    USER_SECOND_NAME_INVALID: 'last name invalid',
    USER_PHONE_INVALID: 'phone invalid',
    USER_FIRST_NAME: 'User first name',
    USER_LAST_NAME: 'User last name',
    USER_PHONE_NUMBER: 'User phone number',
    EMAIL: 'Email',
    NAME: 'First Name',
    LAST_NAME: 'Last Name',
    PHONE_NUMBER: 'Phone Number',
    PASSWORD: 'Password',
    CONF_PASSWORD: 'Confirm password',
    PRIVACY_LINK: 'Please approve terms of use and privacy',
    PRIVACY_INVALID: 'Please approve Terms of Use and Privacy Policy',
    REGISTER: 'Sign up',
    COUPON: 'Please type the coupon if you have it',
    BACK_TO_LOGIN: 'Sign in',
    CORRECT_MAIL_ADDRESS: 'Please enter correct email address',
    PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS: 'Your password must be 6-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or email.',
    PASSWORD_NOT_EQUAL: 'The Password and Repeat password fields should be equal',
    SUCCESSFULLY_REGISTERED: {
      verb: 'Thank you for registering. Please login using your credentials.',
      nine: 'Thank you for registering to Nine Rasa Theatre Online video service,<br>' +
        'To complete the registration process, please click the link we sent to your email {email}',
      zman: 'Thank you for registering,<br>' +
        'To complete the registration, please click on the link sent to your email {email}',
    },
    SUCCESSFULLY_REGISTER_CONFIRM: 'Your account successfully activated, please login',
    SUCCESSFULLY_P_REGISTER_CONFIRM: 'To complete your purchase, please log in and click Buy',
    INVALID_EMAIL: 'Invalid email',
    CLICK_REGISTER: {
      ZMAN: 'If you do not have an account - create a new account.',
      NINE: 'If you do not have an account - click Sign up.',
      VERB: 'If you do not have an account - click Sign up.',
    },
  },
  FORGOT_PASS: {
    CONTINUE_BTN: 'Continue',
    SEND_BTN: 'Send',
    NEW_PASSWORD: 'New password',
    REPEAT_NEW_PASS: 'Confirm password',
    FORGOT_PASS_TITLE: 'Restore password',
    TEXT: 'If you’ve forgotten your password, please enter your registered email address. We’ll send you a link to reset your password.',
    RESTORE: 'Restore',
    SUCCESS: 'We sent a link to reset your password to the email you specified',
    SUCCESS_CHANGED_PASS: 'You have successfully changed your password, please login with your new password',
    CREATE_PASS: 'Create new password',
    CREATE_NEW_PASS: 'Thanks for coming back - please enter your new password details below',
    CREATE_NEW_PASS_NAV: 'CREATE A NEW PASSWORD',
    NOT_CORRECT_TOKEN: 'This link is not valid',
  },
  // Coupons purchase popup
  COUPONS_PURCHASE: {
    NOTE: '(Charged only after 30 day free offer)',
    TITLE: { ZMAN: 'Select the product', NINE: 'Select the package' },
    LABEL: 'Enter the coupon code',
    SUCCESS_PURCHASE: 'Thank you for purchasing, the lecture will be available for viewing for 7 days, enjoyable viewing.',
    INVALID_COUPON: 'Your coupon is not valid',
    APPLY: 'Apply',
    BUY: 'Buy',
    SUBSCRIBE: 'Subscribe',
    INCORRECT_COUPON: 'Incorrect coupon code',
    SUCCESS: 'Successfully used coupon',
    FULL_NAME_TITLE: 'To make a purchase, please type your name and surname:',
    SUBSCRIBE_MONTHLY: 'In order to continue watching premium content, please subscribe to monthly or annually plans below: <br/> You will be charged only after 30-day free trial',
    CONTINUE_BTN: 'Continue',
  },
  // Concurrency alert message popup
  CONCURRENCY_ALERT_MESS: {
    MESSAGE: 'You are already logged in to another device, please log out from the other device and log-in to this device.',
  },

  VIDEO: {
    CONTENT_IS_RESTRICTED: 'Please login to see the content.',
    GUEST_PREVIEW_TIME_LEFT: 'You can watch the first 5 minutes of the lecture free of charge. At the end\n' +
      'The 5 minutes after you have decided that you are interested in continuing to watch the lecture, please\n' +
      'Sign up and keep watching. The cost of watching the lecture is NIS 39.',
    CLOSE_MESSAGE_TEXT: 'Watch first 5 minutes of the video.',
  },
  MODAL: {
    PAGE: {
      TITLE: 'Content not found',
      CONTENT:
        'Selected content was removed from portal. Try to find some interesting content on another page.',
    },
  },

  SUBSRIPTION: {
    LABEL: 'Subscribe',
  },
  EVENT_STATUS: {
    DEFAULT: 'LIVE',
  },
  SECTION_PAGE: {
    REFRESH_BTN: 'The video content will be available for viewing at the time of the planned event. To view - click here.',
  },
  ACCESS: {
    UNSUPPORTED_BROWSER: 'This browser version is not supported, please upgrade.',
    LOCALSTORAGE_ACCESS_DENIED: 'Please enable cookies to watch the video!',
    // LOCALSTORAGE_ACCESS_DENIED: 'Unable to access Local Storage, please allow access or use another browser otherwise the site can work incorrectly!'
  },
  OK: 'Ok',
  PURCHASE: {
    SUCCESS_ZMAN: 'Thanks for subscription',
    SUCCESS_NINE: 'Thank you for subscribing to the Nine Rasa Theater Online',
  },
  SHARING:{
    TITLE: 'Share Samurai channel',
    SHARE: 'Share',
    LINK: 'Link',
  },
  CATEGORIES:{
    MORE: 'View all',
  },
};
