import * as Sentry from '@sentry/vue';

const install = function(Vue, options) {
  Sentry.init({
    Vue,
    ...options.config,
  });
};

export default { install };
