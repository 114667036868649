<template>
  <div
    ref="liveBlock"
    class="channel-event-video channel-event-video--wrapper"
    :class="{ 'position-fixed float-event': isFloatingLive }"
  >
    <media-player
      v-if="actualEvent"
      ref="liveContainer"
      :area="actualEvent"
      :item="area"
      :player-id="playerId"
      :aspect-ratio="isMobile ? '9x16' : '16x9'"
      :shop-layouts="area.creator"
      :is-one="true"
      :is-live="livePlayer"
      :first-event-component="firstEventComponent"
      class="position-relative h-100"
      :vod="false"
    />
  </div>
</template>

<script>
  import { PLAYER_EVENTS, PM, SHOP_EVENTS, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';
  import PopupService from '@/service/popup';
  import normalizeImg from '@/filters/normalizeImg';
  import MediaPlayer from '../player/media/media-player.vue';
  import device from '@/service/device-service';
  import ResponsiveImage from '../reponsive-image';

  export default {
    name: 'EventStreem',
    components: {
      MediaPlayer,
      ResponsiveImage,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      firstEventComponent: {
        type: Boolean,
        default: false,
      },
      livePlayer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        pmSubscriptions: {
          [SHOP_METHODS.CLOSE_PLAYER]: this.closeLivePlayer,
          [SHOP_EVENTS.FLOAT_START_DRAG]: this.handleStartDrag,
          [SHOP_EVENTS.FLOAT_END_DRAG]: this.handleEndDrag,
          [SHOP_METHODS.SHARE_ITEM]: this.handleShare,
          [PLAYER_EVENTS.ENDED]: this.onVideoEnded,
          [PLAYER_EVENTS.DURATION_CAHNGE]: this.clearActualEvent, // vimmi player event
          [SHOP_METHODS.OPEN_PRODUCT_POPUP]: this.handleOpenProductPopup,
          // [SHOP_METHODS.CLOSE_FLOATING]: this.handleCloseFloating,
          [SHOP_METHODS.SWITCH_MODE]: this.switchMode,
        },
        pmSubscribed: [],
        elementCoords: null,
        currentCoords: null,
        liveBlock: null,
        eventTrigger: false,
        playerId: Math.ceil(Math.random() * 10000000),
        timeout: null,
      };
    },
    computed: {
      miniPlayerFromProduct() {
        return this.$store.getters['popup/miniPlayerFromProduct'];
      },
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      isLiveFloating() {
        return this.$store.getters['popup/isLiveFloating'];
      },
      isVodFloating() {
        return this.$store.getters['popup/isVodFloating'];
      },
      isFirstEventComponentFloating() {
        return this.$store.getters['player/isFirstEventComponentFloating'];
      },

      isSecondEventComponentFloating() {
        return this.$store.getters['player/isSecondEventComponentFloating'];
      },

      isFloatingLive() {
        return this.isMiniatureIframe && this.isLiveFloating;
      },
      actualEvent() {
        return this.area?.actualEvent;
      },
      image() {
        if (!this.actualEvent) {
          return null;
        }

        const imgPath = this.isMobile
          ? this.actualEvent.poster
          : this.actualEvent.thumbnail;

        return this.normalizeImg(imgPath, 'thumb', 'HD', 'SD');
      },
      isMobile() {
        return device.isAnyMobile();
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      currentLive() {
        return this.$store.getters['dataAreasLive/currentLive'];
      },
      playerCoords() {
        return this.$store.getters['player/playerCoords'];
      },
      isRestore() {
        return this.$store.getters['player/isRestore'];
      },
      playingLive() {
        return this.$store.getters['dataAreasLive/playingLive'];
      },
      finishedLives() {
        return this.$store.getters['dataAreasLive/finishedLives'];
      },
    },
    watch: {
      eventTrigger(value) {
        if (value) {
          this.eventTrigger = false;
        }
      },
    },
    created() {
      this.$bus.$off('VideoPlayerPlayAction', this.eventPlayerMethods);
      this.subscribeToPlayerEvents(this.pmSubscriptions);
    },
    beforeDestroy() {
      this.unSubscribePlayerEvents();
    },
    mounted() {
      this.$bus.$on('VideoPlayerPlay', (currentItem) => {
        if (currentItem.id !== this.actualEvent.id) {
          this.$bus.$emit('VideoPlayerPlayAction', 'MUTE_OFF');
        }
      });
      this.liveBlock = this.$refs.liveBlock;
      if (this.playerCoords) {
        this.currentCoords = { ...this.playerCoords };
      }
    },

    methods: {
      subscribeToPlayerEvents(subscriptions) {
        Object.keys(subscriptions).forEach(key => {
          this.pmSubscribed.push(PM.inst().target(this.playerId + '_video_player').on(key, subscriptions[key]));
        });
      },
      unSubscribePlayerEvents() {
        PM.inst().off(this.pmSubscribed);
        this.pmSubscribed = [];
      },
      normalizeImg,

      onVideoEnded(data) {
        const currentLiveFinished = this.finishedLives.find(
          (live) => live.id === data.id,
        );

        if (currentLiveFinished && !currentLiveFinished.end) {
          this.endedLive();

          this.$store.commit('dataAreasLive/changeStatusLive', {
            id: data.id,
            finished: true,
            end: true,
          });
        }
      },

      async closeLivePlayer() {
        if (this.$route.params.id === this.currentLive.slug) {
          this.handleCloseFloatPlayer();
          return;
        }

        this.$bus.$emit('clearLiveFloating');
        await this.$bus.$emit('setCurrentPage', null);
        this.$store.commit('popup/setScreenType', 'full');
        this.$store.commit('popup/setFloatingMode', null);
        await this.$bus.$emit('setSound', 'SET_SOUND');
      },

      clearActualEvent(data) {
        const currentLiveFinished = this.finishedLives.find(
          (live) => live.id === this.area?.actualEvent?.id && live.finished,
        );

        if (currentLiveFinished) {
          let timer = data.duration
            ? Math.floor(data.duration - data.position) + 1
            : 11;

          clearTimeout(this.timeout);
          this.timeout = null;

          this.timeout = setTimeout(
            () => {
              this.endedLive();

              this.$store.commit('dataAreasLive/changeStatusLive', {
                id: this.area?.actualEvent?.id,
                finished: true,
                end: true,
              });

              clearTimeout(this.timeout);
              this.timeout = null;
            },
            timer >= 11 ? 11000 : timer * 1000,
          );
        }
      },
      onMoveElement({ x, y }) {
        const posX = x - this.currentCoords.x + this.elementCoords.x;
        const posY = y - this.currentCoords.y + this.elementCoords.y;
        if (!this.liveBlock.classList.contains('float-event')) return;
        this.liveBlock.setAttribute(
          'style',
          `top: ${posY}px !important; left: ${posX}px !important`,
        );
        this.$store.commit('player/SET_PLAYER_COORDS', { x: posX, y: posY });
      },
      setProductPopup(data) {
        data.item.link = data.link;
        data.item.ref = data.ref;
        this.$bus.$emit('toggleProductPopup', { item: data.item });
      },
      handleCloseFloatPlayer() {
        this.$bus.$emit('closeProductPopup');
        const timeout = setTimeout(() => {
          this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
          this.$store.commit('popup/setScreenType', 'full');
          this.$store.commit('popup/setFloatingMode', null);
          this.$store.commit('player/SET_PLAYER_COORDS', null);
          clearTimeout(timeout);
        }, 0);
      },
      handleStartDrag(data) {
        if (!this.isMiniatureIframe) return;
        if (!this.currentCoords) {
          this.currentCoords = data.position;
          this.elementCoords = {
            x: this.liveBlock.getBoundingClientRect().x,
            y: this.liveBlock.getBoundingClientRect().y,
          };
        }
        this.onMoveElement(data.position, data.event);
      },
      handleEndDrag() {
        if (!this.isMiniatureIframe) return;
        this.elementCoords = null;
        this.currentCoords = null;
      },
      handleShare(data) {
        const link = data.link ? data.link : `/s/${data.creatorSlug}`;
        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}${link}`,
          currentStoreName: data.creator,
          item: this.currentLive,
        });
      },
      handleOpenProductPopup(data = {}) {
        data.item && this.setProductPopup(data);
      },
      switchMode(data) {
        if (!data.ref.isStream) return;
        if (this.$route.params.id !== data.creator.slug) {
          this.$store.commit('player/SET_RESTORE', true);
          this.$router.push(`/s/${data.creator.slug}`);
        }
        if (!this.isRestore) {
          this.onCloseFloating(data);
        }
        this.eventTrigger = true;
      },
      onCloseFloating(data) {
        this.$emit('closePopupVOD');
        this.$store.commit('player/SET_ROUTER_TO_LIVE', true);
        this.$store.commit('dataAreasLive/setPlayingLive', true);
        if (this.miniPlayerFromProduct) {
          this.$bus.$emit('closeProductPopup');
        }
        this.$bus.$emit('handleCloseFloatPlayer', data.itemConfig.item_id);
        this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
        this.$store.commit('player/SET_PLAYER_COORDS', null);
      },

      endedLive() {
        this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
        this.$store.commit('popup/setMiniPlayerFromProduct', false);

        if (this.playingLive) {
          PopupService.setScreenType('full');
          PopupService.setFloatingMode(null);
        }

        document
          .querySelector('.channel-event-video--wrapper.float-event')
          ?.removeAttribute('style');

        this.$store.commit('dataAreasLive/setCurrentLive', null);
      },
    },
  };
</script>
