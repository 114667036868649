<template>
  <div class="row justify-content-center">
    <h5 class="animated fadeIn text-center mt-4 mb-4 px-2">
      {{ message }}
    </h5>

    <div
      v-if="actions"
      class="text-center"
    >
      <template v-for="(text, method, index) in actions">
        <button
          v-if="_self[method]"
          :key="index"
          class="btn-danger btn btn-block"
          :class="'msg-btn-'+method"
          type="button"
          @click="_self[method]"
        >
          {{ text }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ConcurrencyMessageBlock",
    props: {
      isActive: {
        type: [Boolean, String],
        default: false,
      },
      message: {
        type: String,
      },
      actions: {
        type: Object,
        default: null,
      },
    },
    methods: {
      close() {
        if (this.isActive) {
          this.$emit('update:isActive', false);
          // this.$body.closeModal();
        }
      },
    },
  }
</script>

<style scoped lang="scss">

</style>