export default function (value) {
  if (value === undefined || value === null) return '0:00:00';

  let hours = Math.floor(value/3600);
  let x = value%3600;
  let minute = Math.floor(x / 60);
  let second = value % 60;

  if (hours === 0) {
    return '' + minute + ':' + ((second < 10) ? '0' + second : second);
  } else
  return '' + hours + ':' +((minute < 10) ? '0' + minute : minute)  + ':' + ((second < 10) ? '0' + second : second);

}
