<template>
  <div 
    class="badge"
    :style="{
      color: badge.fg_color,
      backgroundColor: badge.bg_color,
    }"
  >
    <img 
      :src="badge.icon" 
      alt="Empty"
      class="badge__image"
    >

    <div
      class="badge__title"
    >
      <span
        class="badge__title--chunk"
      >
        {{ titlesArray[0] }}
      </span>

      <span
        class="badge__title--chunk"
      >
        {{ titlesArray[1] }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Badge',
    props: {
      badge: {
        type: Object,
        default: null,
      },
      title: {
        type: String,
        default: '',
      },
      wideMode: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      titlesArray() {
        const titlesArr = this.title.split(' ');
        let modifyTitlesArr = null;

        switch(titlesArr.length) {
          case 3:
            modifyTitlesArr = [titlesArr[1], titlesArr.slice(-2).join(' ')];
            return modifyTitlesArr;
          case 4:
            modifyTitlesArr = [titlesArr.slice(0, 2).join(' '), titlesArr.slice(-2).join(' ')];
            return modifyTitlesArr;
          default:
            return titlesArr;
        };
      },
    },
  }
</script>

<style lang="scss" scoped>
  .badge {
    display: inline-flex;
    align-items: center;
    height: 32px;
    border-radius: 32px;
    margin: 5px 5px 0px 0px;
    cursor: pointer;
    overflow-x: hidden;
    background-color: tomato;
    padding: 0px 8px;
    font-size: 12px;
    font-weight: 600;

    &__image:first-of-type {
      width: 16px;
      height: 16px;
      margin: 0px 8px 0px 0px;
      border-radius: unset;
    }

    &__title {
      display: flex;
      flex-direction: column;
      margin: 0px 8px 0px 0px;

      &--chunk {
        text-transform: uppercase;
        font-size: 8px;
      }
    }
  }

</style>