<template>
  <div class="email-popup__container" @click.prevent.stop="closeModal">
    <div class="email-popup__body">
      <button class="email-popup__return-button" @click="returnToPrevRoute">
        <i class="fas fa-chevron-left"/>
      </button>
      <div class="email-popup__email-icon">
        <img :src="emailImage">
      </div>
      <p class="email-popup__message">
        We’ve sent an email to reset your password. Please check your email and
        click the link to reset the password.
      </p>
      <button class="email-popup__close-button" @click="closeModal">
        Close
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'EmailSentPopup',
    data() {
      return {
        emailImage: require('src/assets/icons/mail.svg'),
      };
    },
    methods: {
      closeModal() {
        this.$emit('closeEmailPopup');
      },
      returnToPrevRoute() {
        this.$router.back();
      },
    },
  };
</script>
<style lang="scss" scoped>
@import './email-sent-popup.scss';
</style>
