import UserConfig from "@/config/config_user";
import CustomerConfig from "@/config/config_verb";

let configs = {
  'fungus-my-account': false,
  'fungus-my-account-data': false,
  'guest-users': true,
  'fungus-login': false,
  'rtl': false,
  'shoprz-analytics': true,
  'buh-analytics': false,
  'fbq-analytics': false,
  'favorite-btn': true,
  'favorite-btn-series': true,
  'history-list': false,
  'resume-watch': true,
  'share-btn': true,
  'share-btn-category': false,
  'share-btn-open-app': false,
  'new-live-design': true,
  'block-subscribe-btn': false,
  // 'quest-mode': false,
  'use-scroll-area': true,
  'live-item-circle': false,
  'live-item-background': false,
  'live-item-class': false,
  'cache-for-request': false,
  'webview_feature': false,
}
configs = Object.assign(configs, CustomerConfig?.features || {});
if (process.env.NODE_ENV !== 'production') {

  configs = Object.assign(configs, UserConfig?.features || {});

}


export default Object.keys(configs).filter(key => configs[key]);
