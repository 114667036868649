export default languages => {
  if (!languages) {
    return {}; // for assign
  }

  let keys = Object.keys(languages);
  let result = {};
  let normalisation = {
    eng: ['eng', 'en'],
    tha: ['tha', 'th'],
    ro: ['ro', 'rom'],
  };

  keys.forEach(element => {
    // let shouldNormalise = .some(el => el)
    Object.keys(normalisation).some(el => {
      if (normalisation[el].indexOf(element) > -1) {
        result[el] = languages[element];
        return true;
      }
      return false;
    });
  });

  return result;
};
