import { amsClient } from 'src/service/ams';

const state = () => ({
  stores: null,
  store: null,
  showStore: true,
  currentCollectionKey: 'All',
  totalCount: 20,
  loading: false,
  sort: null,
  filters: [],
  allStoresObj: null,
  layoutType: 'grid',
});

const getters = {
  allStores(state) {
    return state.allStoresObj.items;
  },
  storeCount(state) {
    return state.allStoresObj.head.count;
  },
  loading(state) {
    return state.loading;
  },
  allStore(state) {
    return state.stores;
  },
  store(state) {
    return state.store;
  },
  sort(state) {
    return state.sort;
  },
  filters(state) {
    return state.filters;
  },
  colections(state) {
    return state.stores
      ? state.stores.filter((item) => item.link === state.currentCollectionKey)
      : [];
  },

  catsColection(state) {
    const arr1 = state.stores
      ? state.stores.filter((item) => item.link === state.currentCollectionKey)
      : [];
    return arr1[0].cats.filter((_, i) => i < state.totalCount);
  },
  allCatsColection(state) {
    const arr1 = state.stores
      ? state.stores.filter((item) => item.link === state.currentCollectionKey)
      : [];
    return arr1[0].cats;
  },
  CollectionKey(state) {
    return state.currentCollectionKey;
  },
  showStoresAll(state) {
    return state.showStore;
  },
  layoutType(state) {
    return state.layoutType;
  },
  head(state) {
    return state.allStoresObj?.head;
  },
};

const mutations = {
  setAllStoresObj(state, payload) {
    state.allStoresObj = payload;
  },
  setAllStore(state, stores) {
    state.stores = stores;
  },
  setStore(state, store) {
    state.store = store;
  },
  setSort(state, payload) {
    state.sort = payload
  },
  setFilter(state, payload) {
    state.filters = payload
  },
  setCollectionKey(state, key) {
    state.currentCollectionKey = key;
  },
  setShowStore(state, show) {
    state.showStore = show;
  },
  setCatsColection(state, totalCount) {
    state.totalCount = totalCount;
  },
  setLoadingState(state, payload) {
    state.loading = payload;
  },
  setLayoutType(state, payload) {
    state.layoutType = payload;
  },
};

const actions = {
  toggleLoading({ commit }, payload) {
    commit('setLoadingState', payload);
  },
  async getAllStore({ state, commit }, { sort = null, filters = '' } = {}) {
    if (!state.sort && !state.filters.length && !state.allStoresObj) {
      commit('setLoadingState', true);
    }

    const urls = {
      'followers': 'shoprzapi/stores/all/?',
      'title': 'shoprzapi/stores/all/?sort=title&',
    }

    filters = `filters=${filters}`
    const link = (urls[sort] || urls['followers']) + (filters || '');

    try {
      const stores = await amsClient.callAms(link);

      commit('setAllStoresObj', stores);

      const allStore = (stores) => {
        const { categories } = stores.head;
        const cats = categories && Object.keys(categories).slice(1);

        if (cats?.length) {
          return cats.map((key) => {
            const arrayString = categories[key].split('/');
            const link = arrayString[arrayString.length - 2];

            return {
              label: key,
              link,
              cats: stores.items.filter((item) => item.cats?.includes(key)),
            };
          });
        }
      };
      commit('setAllStore', allStore(stores));
      commit('setLoadingState', false);
    } catch (error) {
      console.log(error);
    }
  },
  setLayoutTypeAction({ commit }, payload) {
    commit('setLayoutType', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
