<template>
  <b-modal 
    v-model="showModal"
    centered 
    :size="'lg'"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="error-modal-block">
      <div class="error-modal-block__img">
        <img :src="require('@/assets/icons/term-of-use-error-icon.svg')" alt="error-star">
      </div>

      <div class="error-modal-block__title">
        To finalize the registration, please
        accept the Terms of use
      </div>

      <div class="error-modal-block__btn-wrapper">
        <button 
          class="cancel-btn"
          @click="closeModal"
        >
          Cancel
        </button>

        <button 
          class="go-back-btn"
          @click="openTermsOfUse"
        >
          Go to Terms of Use
        </button>        
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'TermOfUseError',
    data() {
      return {
        showModal: false,
      };
    },
    watch: {
      showModal(val) {
        this.$emit('change:modal', 'termOfUseErrorModal', val);
      },
    },
    mounted (){
      this.$bus.$on('openTermsErrorModal', () => {
        this.showModal = true;
      });
    },
    destroyed() {
      this.$bus.$off('openTermsErrorModal');
    },
    methods: {
      closeModal() {
        this.showModal = false;
      },
      openTermsOfUse() {
        this.showModal = false;
        
        this.$bus.$emit('openTermsOfUseDialog', {
          isAcceptHidden: false,
          callFromFooter: false,
        });
      },
    },
  }
</script>

<style lang='scss' scoped>
    .error-modal-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $button-white-txt-color;
        padding: 56px 120px 50px;
        margin: 0px auto;
        max-width: 630px;
        z-index: 1056;

        &__img {
            margin-bottom: 22px;
        }

        &__title {
            max-width: 320px;
            text-align: center;
            margin-bottom: 35px;
            font-weight: 700;    
        }

        &__btn-wrapper {
          display: flex;
          width: 400px;

          button {
            cursor: pointer;
            padding: 15px 0px;
            font-weight: 600;
            outline: none;
            border: none;
            font-size: 15px;
            width: 50%;
          }

          .cancel-btn {
            background-color: $cancel-button-bg-color;
            margin-right: 10px;
          }

          .go-back-btn {
            background-color: $accept-button-bg-color;
            color: $button-white-txt-color;
          }
        }
    }

    @media (max-width: 530px) {
      .error-modal-block {
        padding: 50px 20px 20px;
        max-width: 315px;

        &__title {
            max-width: 222px;
        }

        &__btn-wrapper {
          flex-direction: column;
          max-width: 275px;

          button {
            width: 100%;
          }

          .cancel-btn {
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
      }
    } 
</style>