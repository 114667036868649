<template>
  <div v-if="item" class="preview-overlay">
    <div class="preview-overlay__header">
      <img v-if="item.creator.picture" :src="item.creator.picture" alt="" class="preview-overlay__image">

      <div class="preview-overlay__user">
        <div v-if="item.creator.name" class="preview-overlay__user-name preview-overlay--text-ellipsis">
          {{ item.creator.name }}
        </div>

        <div v-if="item.creator.followers" class="preview-overlay__viewers-count preview-overlay--text-ellipsis">
          {{ item.creator.followers }} followers
        </div>
      </div>
    </div>

    <div class="preview-overlay__content">
      <div v-if="item.title" class="preview-overlay__item-name preview-overlay--text-ellipsis">
        {{ item.title }}
      </div>

      <div v-if="item.description" class="preview-overlay__item-description">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';

  export default {
    name: 'ResponsiveVideoPreviewOverlay',
    props: ['itemLink'],
    data() {
      return {
        item: null,
      }
    },
    mounted() {
      if (this.itemLink) {
        this.getItemData(this.itemLink)
          .then(response => {
            this.item = response.head;
          })
          .catch(error => console.log(error));
      }
    },
    methods: {
      getItemData(url) {
        return amsClient.callAms(url, { cache: false });
      },
    },
  }
</script>

<style lang="scss">
  .preview-overlay {
    padding: 15px;
    position: absolute;
    top: 0;
    right: 0;
    width: 210px;
    height: 100%;
    background: black;
    color: #fff;
    text-align: left;
    &:hover {
      color: #fff;
    }
    @media(max-width: 600px) {
      display: none;
    }
    &--text-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    &__image {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    &__user {
      width: calc(100% - 40px);
    }
    &__user-name {
      font-size: 15px;
      font-weight: 700;
    }
    &__viewers-count {
      font-size: 14px;
      font-weight: 400;
    }
    &__content {
      font-size: 14px;
    }
    &__item-name {
      margin-bottom: 5px;
      font-weight: 700;
    }
    &__item-description {
      font-weight: 400;
    }
  }
</style>