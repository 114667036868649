import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import popup from './popup/popup.store';
import player from './player/player.store';
import checkout from './checkout/checkout.store';
import allStoreCategories from './allStoreCategories/allStoreCategories';
import catalogProducts from './catalogProducts/catalogProducts';
import vodPreview from './vodPreview/vodPreview';
import searchPage from './searchPage/searchPage';
import chachedPreviewMedia from './chachedPreviewMedia/chachedPreviewMedia';
import dataAreasLive from './dataAreasLive/dataAreasLive';
import termsAgreement from './terms-of-use/termsOfUse';
import upcomingModule from './upcomingModule/upcomingModule';
import searchInStore from './searchInStore/searchInStore';
import navLinks from './navLinks';
import suggestions from './suggestions/suggestions';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  strict: debug,
  modules: {
    popup,
    checkout,
    allStoreCategories,
    catalogProducts,
    vodPreview,
    searchPage,
    chachedPreviewMedia,
    dataAreasLive,
    termsAgreement,
    upcomingModule,
    searchInStore,
    player,
    navLinks,
    suggestions,
  },
  plugins: debug ? [createLogger] : [],
});
