export default [
  {
    name: 'becomeAffiliate',
    path: '/become-affiliate',
    component: () => import('./become-affiliate.view.vue'),

    meta: {
      title: 'Become an Affiliate',
    },
  },
];
