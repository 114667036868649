import WishlistStoreObject from './wishlist.store';
import StoreWrapperService from '@/store/store-wrapper.js';

export const WishlistStore = new StoreWrapperService({
  name: 'wishlist',
  localStore: WishlistStoreObject,
  options: {
    mutations: true,
    getters: true,
    actions: true,
  },
  ignore: {
    mutations: [],
    getters: [],
    actions: [],
  },
});

export default WishlistStore;
