import { amsClient } from '@/service/ams';

// State
const state = () => ({
  searchData: null,
  currentState: 'All',
  currentItem: null,
  isSubMenu: false,
  loading: false,
  productsLoading: false,
  productItems: null,
  carousels: null,
  carouselsLoading: false,
  productsArrayCopy: null,
  productPrices: [],
});

// Getters
const getters = {
  loading: state => {
    return state.loading;
  },
  productsLoading: state => {
    return state.productsLoading;
  },
  searchData: state => {
    return state.searchData;
  },
  currentState: state => {
    return state.currentState;
  },
  currentItem: state => {
    return state.currentItem;
  },
  isSubMenu: state => {
    return state.isSubMenu;
  },
  nav: state => {
    const items = state.searchData?.items?.filter(item => item.count) || [] 

    return items.map(item => {
      if (item?.title?.includes('Live')) {
        return {
          ...item,
          title: 'Events',
        }
      }

      return item;
    });
  },
  checkSubMenuVisibility: state => {
    return state.searchData?.items?.some((item) => {
      if (!item?.catalog) { return false; }

      return item.title === state.currentState
        || item?.catalog?.some((subitem) => subitem.title === state.currentState);
    })
  },
  allCounts: state => {
    return state.searchData?.items?.reduce((total, item) => total + item.count, 0);
  },
  carousels: state => {
    return state.carousels;
  },
  carouselsLoading: state => {
    return state.carouselsLoading;
  },
  productItems: state => {
    return state.productItems;
  },
  getProductsArrayCopy: state => {
    return state.productsArrayCopy;
  },
  getProductPrices: state => {
    return state.productPrices;
  },
  head(state) {
    return state.searchData?.head;
  },
}

// Actions
const actions = {
  toggleLoading({ commit }, payload) {
    commit('setLoadingState', payload);
  },
  toggleProductsLoading({ commit }, payload) {
    commit('setProductsLoadingState', payload);
  },
  setSearchData({ dispatch, commit, state }, payload) {
    commit('setSearchData', payload);

    dispatch('changeLink', { item: { title: 'All' }, isSubMenu: false });
  },
  async setProductItemsWithSubMenu({ commit }, item) {
    commit('setProductsLoadingState', true);
    commit('setProductItems', null);

    try {
      const data = item?.skipApiCall
        ? { products: item.items, head: { title: item.title, itemsType: item.carouselType } }
        : await amsClient.callAms(item.link);
      console.log('data:', data);

      commit('setProductItems', data);
      commit('setProductsLoadingState', false);
    } catch (e) {
      commit('setProductsLoadingState', false);
    }
  },
  async changeLink({ dispatch, commit, state }, { item, isSubMenu }) {
    commit('setCurrentState', item?.title || null);
    commit('setCurrentItem', item || null);
    commit('setIsSubMenu', isSubMenu || null);

    if (isSubMenu) {
      dispatch('setProductItemsWithSubMenu', item);
    } else {
      let carousels = [];
      commit('setCarouselsLoadingState', true);

      const checkType = (title) => {
        if (title === 'Products') {
          return  'vertical';
        } else if (title === 'Stores') {
          return  'circle';
        }

        return 'horizontal';
      }

      if (state.currentState === 'All') {
        carousels = state.searchData?.items?.filter(nav => nav.count)?.map((carousel) => {
          return { ...carousel, carouselType: checkType(carousel.title), isDeep: false };
        });
      } else {
        const mainCarousel = {
          ...item,
          carouselType: checkType(item.title),
          isDeep: true,
          skipApiCall: true,
        };
        const subCarouselsData = await Promise.allSettled(
          item?.catalog?.filter(nav => nav.count)?.map(catalogItem => amsClient.callAms(catalogItem.link)) || [],
        );
        const subCarousels = subCarouselsData?.filter(resElem => resElem?.value)?.map(({ value }) => {
          return {
            carouselType: checkType(item.title),
            isDeep: true,
            title: value.head.title,
            items: value.products,
            link: `shoprzapi/${value.ams_url?.split('shoprzapi/')?.pop()?.trim()}`,
          }
        });

        carousels = [...Array.from(subCarousels), mainCarousel];
      }
      if (carousels.length === 1) {
        dispatch('setProductItemsWithSubMenu', carousels[0]);
        commit('setIsSubMenu', carousels[0].isDeep || null);
      }
      commit('setCarousels', carousels);
      commit('setCarouselsLoadingState', false);
    }
  },
  setProductsArrayCopy({ commit, state }, payload) {
    if(!payload && state.carousels) {
      const productsObj = state.carousels.find(item => item.title === 'Products');

      commit('setProductsArrayCopy', productsObj?.items);

      return;
    };

    commit('setProductsArrayCopy', payload);
  },
  setProductPrices({commit, state}) {
    if(state.carousels?.length) {
      const productObj = state.carousels.find(item => item.title === 'Products');

      const productPricesArr = productObj ? productObj.items.map(prod => +prod.price) : [];

      commit('setProductPrices', productPricesArr);

      return;
    };

    commit('setProductPrices', []);
  },
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loading = payload;
  },
  setProductsLoadingState(state, payload) {
    state.productsLoading = payload;
  },
  setSearchData(state, payload) {
    state.searchData = payload;
  },
  setCurrentState(state, payload) {
    state.currentState = payload;
  },
  setCurrentItem(state, payload) {
    state.currentItem = payload;
  },
  setIsSubMenu(state, payload) {
    state.isSubMenu = payload;
  },
  setProductItems(state, payload = null) {
    state.productItems = payload;
  },
  setCarousels(state, payload = null) {
    state.carousels = payload;
  },
  setCarouselsLoadingState(state, payload) {
    state.carouselsLoading = payload;
  },
  setProductsArrayCopy(state, payload) {
    state.productsArrayCopy = payload;
  },
  setProductPrices(state, payload) {
    state.productPrices = payload;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
