<template>
  <div
    ref="vodContainer"
    :class="{
      'shop_screen-portrait': screenMode === 'portrait',
      'shop_screen-landscape': screenMode === 'landscape',
      'shop_screen-miniature': screenType === 'miniature'
    }"
    class="vod-popup-container"
    :style="[vodPosition && { 'top': vodPosition.y + 'px', 'left': vodPosition.x + 'px' } ]"
    @click.prevent.stop="closeModal"
  >
    <div ref="popup" class="vod-popup__container">
      <div v-show="screenType === 'miniature' && isLoading" class="loading" @click.stop.prevent>
        <span class="spinner" />
      </div>

      <media-player
        v-if="!isUpdateVideo"
        class="vod-video-container"
        :area="item"
        :shop-layouts="item.creator && item.creator.id ? item.creator : null"
        :player-id="playerId"
        is-one
      />
    </div>
  </div>
</template>
<script>
  import { PM, PMVod, SHOP_EVENTS, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';
  import MediaPlayer from '@/components/player/media/media-player';
  import { screenMode } from 'vimmi-web-utils/cjs/screenMode';
  import device from '@/service/device-service.js';
  import GtagService from '@/service/gtag';
  import PopupService from '@/service/popup';
  import Vue from 'vue';

  export default {
    name: 'VodPopup',
    components: {
      MediaPlayer,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },

      productPopupVod: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        pmSubscriptions: {
          [SHOP_METHODS.CLOSE_PLAYER]: this.closePlayer,
          [SHOP_METHODS.SWITCH_MODE]: this.switchMode,
          // [SHOP_METHODS.CLOSE_FLOATING]: this.closeFloating,
          [SHOP_METHODS.SHARE_ITEM]: this.handleShare,
          [SHOP_EVENTS.FLOAT_START_DRAG]: this.handleStartDrag,
          [SHOP_EVENTS.FLOAT_END_DRAG]: this.handleEndDrag,
          ...(!this.item.playerOptions ? {
            [SHOP_METHODS.CLOSE_VOD_POPUP]: this.closeModal,
            [SHOP_METHODS.OPEN_STORE]: this.handleRouteToStore,
          } : {}),
        },
        pmSubscribed: [],
        screenMode: screenMode(),
        vodContainer: null,
        isUpdateVideo: false,
        elementCoords: null,
        currentCoords: null,
        floatingPosition: null,
        prevTitle: document.title,
        playerId: Math.ceil(Math.random() * 10000000),
      };
    },
    computed: {
      miniPlayerFromProduct() {
        return this.$store.getters['popup/miniPlayerFromProduct'];
      },
      screenType() {
        return this.$store.getters['popup/screenType'];
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      isLoading() {
        return this.$store.getters['popup/getLoadingIframe'];
      },
      playerCoords() {
        return this.$store.getters['player/playerCoords'];
      },
      vodPosition() {
        if (this.isMiniatureIframe) {
          if (!this.floatingPosition) {
            return this.playerCoords;
          } else {
            return this.floatingPosition;
          }
        }
        return null;
      },

      newTitle() {
        return this.$store.getters['popup/getDocumentTitle'];
      },
    },
    watch: {
      item: {
        handler(val) {
          this.isUpdateVideo = true;

          if (val.share) {
            this.$bus.$emit('setCurrentPage', val.share);
          }

          this.$nextTick(() => {
            this.isUpdateVideo = false;
          });
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.$bus.$on(
        this.playerId + '_videoComponentReady',
        this.event_videoComponentReady,
      );
      if (this.playerCoords) {
        this.elementCoords = { ...this.playerCoords };
      }
    },
    mounted() {
      if (!this.productPopupVod) {
        this.$bus.$emit('updateTitle', this.item.seo_metadata?.title || this.item.title);
      }

      if (!this.isMiniatureIframe) {
        this.$store.commit('player/SET_VOD_ITEM', this.item);
      }

      Vue.prototype.$floatingVodRef = this.$refs.vodContainer;

      GtagService.sendEvent('srz_vod_view', {
        store_id: this.item.creator.id,
        store_slug: this.item.creator.slug,
        store_name: this.item.creator.name,
        vod_id: this.item.id,
        vod_name: this.item.title,
      });

      this.vodContainer = this.$refs.vodContainer;

      if (this.screenType === 'full') {
        document.documentElement.style.setProperty('overflow', 'hidden');
      }

      window.parent.addEventListener('orientationchange', this.updateScreenMode);

    },
    beforeDestroy() {
      this.unSubscribePlayerEvents();
      this.$bus.$off(
        this.playerId + '_videoComponentReady',
        this.event_videoComponentReady,
      );
      document.documentElement.removeAttribute('style');
      window.parent.removeEventListener('orientationchange', this.updateScreenMode);
    },
    methods: {
      subscribeToPlayerEvents(subscriptions) {
        Object.keys(subscriptions).forEach(key => {
          this.pmSubscribed.push(PM.inst().target(this.playerId + '_video_player').on(key, subscriptions[key]));
        });
      },
      unSubscribePlayerEvents() {
        PM.inst().off(this.pmSubscribed);
        this.pmSubscribed = [];
      },
      event_videoComponentReady() {
        this.$nextTick(() => {
          this.subscribeToPlayerEvents(this.pmSubscriptions);
        });
      },
      async closeModal() {
        this.$bus.$emit('updateTitle', this.newTitle || this.prevTitle);

        this.$store.commit('popup/setDocumentTitle', null);

        if (!this.item.playerOptions) {
          await this.$emit('closePopupVOD');
          await this.$bus.$emit('setCurrentPage', null);
          await this.$bus.$emit('setSound', 'SET_SOUND');
        } else {
          // await this.$bus.$emit(this.item.eventIdentifier + '_changeAudioMode');
        }
      },
      onMoveElement({ x, y }) {
        const posX = x - this.currentCoords.x + this.elementCoords.x;
        const posY = y - this.currentCoords.y + this.elementCoords.y;
        this.floatingPosition = { x: posX, y: posY };
        this.$store.commit('player/SET_PLAYER_COORDS', { x: posX, y: posY });
      },
      updateScreenMode() {
        this.vodContainer.removeAttribute('style');
      },
      closePlayer() {
        this.$bus.$emit('closeMiniVod');
        this.$store.commit('popup/setScreenType', 'full');
        this.$store.commit('popup/setFloatingMode', null);
        this.$store.commit('player/SET_PLAYER_COORDS', null);
      },
      switchMode(data) {
        if (data.mode === 'l') {
          if (!data.ref.isStream) {
            document.documentElement.style.overflow = 'hidden';
          }
          this.vodContainer.removeAttribute('style');
          PopupService.setScreenType('full');

          if (this.miniPlayerFromProduct) {
            this.$bus.$emit('closeProductPopup');
            this.$store.commit('popup/setMiniPlayerFromProduct', false);
          }

          this.$bus.$emit('handleCloseFloatPlayer');
        }
      },
      handleStartDrag(data) {
        if (!this.isMiniatureIframe) return;

        if (!this.currentCoords) {
          this.currentCoords = data.position;
          this.elementCoords = {
            x: this.vodContainer.getBoundingClientRect().x,
            y: this.vodContainer.getBoundingClientRect().y,
          };
        }

        this.onMoveElement(data.position, data.event);
      },
      handleEndDrag() {
        if (!this.isMiniatureIframe) return;

        this.elementCoords = null;
        this.currentCoords = null;
      },
      handleShare(data) {
        const link = data.link ? data.link : `/s/${data.creatorSlug}`;

        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}${link}`,
          currentStoreName: data.creator,
          item: this.item,
        });
      },
      handleRouteToStore(data) {
        this.$router.push(`/s/${data.storeName}`);
        this.closeModal();
      },
    },
    beforeRouteLeave(to, from, next) {
      if (device.isAnyMobile()) {
        this.closeModal();
        next(false);
      } else {
        next();
      }
    },
  };
</script>
<style lang="scss">
  @import './vod-popup';
</style>

<style lang="scss" scoped>
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // border-radius: 10px;
    width: 374px;
    height: 210px;
    background-color: black;

    .spinner {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 6px solid rgba(255, 255, 255, 0.4);
      border-top: 6px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: -25px;
      margin-top: -25px;
      animation: spinner 1.3s infinite;
      opacity: .5;
    }
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }

  }
</style>