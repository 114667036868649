<template>
  <div
    class="animated fadeIn"
  >
    <form
      class="auth-form"
      autocomplete="on"
      novalidate
      @submit.prevent.stop="submitForm()"
    >
      <div>
        <h5
          v-if="message.text"
          class="auth-message text-center mt-4 mb-4"
          style="color: #0F1010"
          :class="'message-' + message.status"
          v-html="message.text"
        />
      </div>
      <template>
        <div class="custom-line" />
        <div style="text-align: center">
          <span class="social-login-title">Sign up with</span>
        </div>
        <div class="social-login-wrapper">
          <button
            type="button"
            class="btn btn-login  align-self-center align-button-login"
            @click="loginFromGoogle"
          >
            <div class="log-button-wrapper">
              <span class="log-icon">
                <img :src="require('src/assets/icons/google-icon.png')" alt="">
              </span>
              <span v-t="{ path: 'LOGIN.BY_GOOGLE' }" class="log-title" />
            </div>
          </button>
          <button
            type="button"
            class="btn btn-login  align-self-center"
            @click="fbLogin"
          >
            <div class="log-button-wrapper">
              <span class="log-icon">
                <img :src="require(`@/assets/icons/icon-fb.svg`)" class="icon-fb" alt="">
              </span>
              <span v-t="{ path: 'LOGIN.BY_FACEBOOK' }" class="log-title" />
            </div>
          </button>
        </div>
      </template>
      <div class="divider">
        <span>or</span>
      </div>
      <template v-if="isActiveFeature('register_form_extra_fields')">
        <div class="form-wrap">
          <form-input
            v-model="userData.first_name"
            :label="$t('REGISTER_FORM.USER_FIRST_NAME')"
            name="first_name"
            :error="errors.first_name"
            :toggle-label="toggleName"
            :label-color="LabelColorName"
            :error-label="errors.first_name"
            @validate="validate('first_name')"
            @toggleLabelUp="toggleLabelUp('first_name')"
            @toggleLabelDown="toggleLabelDown('first_name')"
            @checkFields="checkFields('first_name')"
          />
          <div
            v-if="errors.first_name"
            class="valid-field"
          >
            <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="">
            Please enter your first name
          </div>
        </div>
        <div class="form-wrap">
          <form-input
            v-model="userData.last_name"
            :label="$t('REGISTER_FORM.USER_LAST_NAME')"
            :name="'last_name'"
            :error="errors.last_name"
            :toggle-label="toggleLastName"
            :label-color="LabelColorLastName"
            :error-label="errors.last_name"
            @validate="validate('last_name')"
            @toggleLabelUp="toggleLabelUp('last_name')"
            @toggleLabelDown="toggleLabelDown('last_name')"
            @checkFields="checkFields('last_name')"
          />
          <div
            v-if="errors.last_name"
            class="valid-field"
          >
            <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="">
            Please enter your last name
          </div>
        </div>
      </template>
      <div class="form-wrap">
        <form-input
          v-model="userData.email"
          :label="$t('REGISTER_FORM.EMAIL')"
          :name="'register_email'"
          :error="errors.email"
          :toggle-label="toggleEmail"
          :error-label="errorLabelEmail || errors.email"
          :label-color="LabelColorEmail"
          :class="{'error-line': errorLine.email}"
          @validate="validate('email')"
          @toggleLabelUp="toggleLabelUp('email')"
          @toggleLabelDown="toggleLabelDown('email')"
          @checkFields="checkFields('email')"
        />
        <div
          v-if="checkField.email || errors.email"
          class="valid-field"
        >
          <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="">
          Please enter a valid email
        </div>
      </div>
      <div class="form-wrap">
        <form-input
          v-model="userData.password"
          :type="isShowPassword ? 'text' : 'password'"
          :label="$t('REGISTER_FORM.PASSWORD')"
          :name="'register_password'"
          :error="errors.password"
          :toggle-label="togglePassword"
          :error-label="errorLabelPassword || errors.password"
          :label-color="LabelColorPassword"
          :class="{'error-line': errorLine.password}"
          @validate="validate('password')"
          @toggleLabelUp="toggleLabelUp('password')"
          @toggleLabelDown="toggleLabelDown('password')"
          @checkFields="checkFields('password')"
        >
          <button class="show-password-toggler" type="button" @click="onShowHidePassword">
            <img v-if="!isShowPassword" class="icon-eye" :src="require(`@/assets/icons/icon-eye.svg`)" alt="">
            <img v-else class="icon-eye" :src="require(`@/assets/icons/icon-eye-hide.svg`)" alt="">
          </button>
        </form-input>

        <div
          v-if="checkField.password"
          class="valid-field"
        >
          <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="">
          Please enter a password
        </div>
      </div>
      <div class="form-wrap">
        <form-input
          v-model="userData.confirm_password"
          :type="isShowRepeatPassword ? 'text' : 'password'"
          :label="$t('REGISTER_FORM.CONF_PASSWORD')"
          :name="'confirm_password'"
          :error="errors.confirm_password"
          :toggle-label="togglePasswordConfirm"
          :error-label="errorLabelPasswordConfirm || errors.confirm_password"
          :label-color="LabelColorPasswordConfirm"
          :class="{'error-line': errorLine.confirm_password}"
          @validate="validate('confirm_password')"
          @toggleLabelUp="toggleLabelUp('confirm_password')"
          @toggleLabelDown="toggleLabelDown('confirm_password')"
          @checkFields="checkFields('confirm_password')"
        >
          <button class="show-password-toggler" type="button" @click="onShowHideRepeatPassword">
            <img v-if="!isShowRepeatPassword" class="icon-eye" :src="require(`@/assets/icons/icon-eye.svg`)" alt="">
            <img v-else class="icon-eye" :src="require(`@/assets/icons/icon-eye-hide.svg`)" alt="">
          </button>
        </form-input>
        <div
          v-if="checkField.confirm_password"
          class="valid-field"
        >
          <img :src="require(`@/assets/icons/icon-validator.svg`)" alt="">
          Please enter a confirm password
        </div>
      </div>
      <div class="d-flex form-group auth-form-group justify-content-center">
        <button
          :disabled="!isTermsAccepted"
          type="submit"
          class="btn auth-login rounded w-100"
          style="max-width: 100% !important;"
        >
          <span class="d-inline-block text-truncate align-middle w-100">
            <span v-t="{ path: 'REGISTER_FORM.REGISTER' }" />
          </span>
        </button>
      </div>
      <div class="terms-wrapper">
        <div class="checkbox-wrapper">
          <b-form-checkbox
            v-model="isChecked"
            class="checkbox-hover"
            @change="acceptTermsHandler"
          />
        </div>

        <div class="agreement-links-wrapper">
          <span> By signing up, i agree with the </span>
          <span class="terms-wrapper-link" @click="clickTermOfUse"> Terms of Use</span>
          <span> and </span>
          <span class="terms-wrapper-link"> Privacy Policy </span>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import Auth from 'src/service/authService';
  import Config from 'src/service/config';
  import FormInput from '../../common/form-input';
  import { object, string, number, ref } from 'yup';
  import { isActiveFeature } from '@/plugins/feature-toggle';
  import { mapState, mapMutations, mapGetters } from 'vuex';

  import { localStore } from 'vimmi-web-utils/esm/localStore';

  export default {
    name: 'RegisterBlock',
    components: {
      'form-input': FormInput,
    },
    props: [],
    data() {
      return {
        redirectUrl: Config.get('terms_url'),
        isRegistered: false,
        userData: {
          email: '',
          password: '',
          first_name: '',
          last_name: '',
          phone: undefined,
          confirm_password: '',
          coupon_code: null,
        },
        checkField: {
          email: '',
          password: '',
          confirm_password: '',
        },
        errorLine: {
          email: false,
          password: false,
          confirm_password: false,
        },
        message: {},
        fix18n: {},
        signUpShape: {},
        errors: {
          email: false,
          password: false,
          first_name: false,
          last_name: false,
          phone: false,
          confirm_password: false,
        },
        isShowPassword: false,
        isShowRepeatPassword: false,
        isChecked: false,

        toggleEmail: false,
        LabelColorEmail: false,
        errorLabelEmail: false,

        toggleName: false,
        LabelColorName: false,


        toggleLastName: false,
        LabelColorLastName: false,


        togglePassword: false,
        errorLabelPassword: false,
        LabelColorPassword: false,

        togglePasswordConfirm: false,
        errorLabelPasswordConfirm: false,
        LabelColorPasswordConfirm: false,

      };
    },
    computed: {
      ...mapState({
        isTermsAccepted: state => state.termsAgreement.isTermsAccepted,
      }),
      ...mapGetters({
        getIsTermsAccepted: 'termsAgreement/isTermsAccepted',
      }),
    },
    mounted() {
      this.isChecked = this.getIsTermsAccepted;
      this.fix18n = this.$i18n;
      const fields = {
        confirm_password: string()
          .min(6, this.$i18n.t('REGISTER_FORM.PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS'))
          .oneOf([ref('password'), null], this.$i18n.t('REGISTER_FORM.PASSWORD_NOT_EQUAL')),
        password: string()
          .min(6, this.$i18n.t('REGISTER_FORM.PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS'))
          .matches(/^[a-zA-Z0-9]+$/, this.$i18n.t('REGISTER_FORM.PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS'))
          .required(this.$i18n.t('REGISTER_FORM.PASSWORD_CAN_NOT_BE_LESS_THAN_6_CHARACTERS')),
        email: string()
          .email(this.$i18n.t('REGISTER_FORM.CORRECT_MAIL_ADDRESS'))
          .required(this.$i18n.t('REGISTER_FORM.CORRECT_MAIL_ADDRESS')),
        last_name: string()
          .required(this.$i18n.t('REGISTER_FORM.USER_SECOND_NAME_INVALID')),
        first_name: string()
          .required(this.$i18n.t('REGISTER_FORM.USER_FIRST_NAME_INVALID')),
      };


      if (process.env.VUE_APP_CUSTOMER === 'nine') {
        const nineRasaFields = {
          phone: number()
            .min(999999, this.$i18n.t('REGISTER_FORM.USER_PHONE_INVALID'))
            .typeError(this.$i18n.t('REGISTER_FORM.USER_PHONE_INVALID'))
            .required(this.$i18n.t('REGISTER_FORM.USER_PHONE_INVALID')),
          last_name: string()
            .min(2, this.$i18n.t('REGISTER_FORM.USER_SECOND_NAME_INVALID'))
            .max(16, this.$i18n.t('REGISTER_FORM.USER_SECOND_NAME_INVALID'))
            .required(this.$i18n.t('REGISTER_FORM.USER_SECOND_NAME_INVALID')),
          first_name: string()
            .min(2, this.$i18n.t('REGISTER_FORM.USER_FIRST_NAME_INVALID'))
            .max(16, this.$i18n.t('REGISTER_FORM.USER_FIRST_NAME_INVALID'))
            .required(this.$i18n.t('REGISTER_FORM.USER_FIRST_NAME_INVALID')),
        };

        Object.assign(fields, nineRasaFields);
      }

      this.signUpShape = object().shape(fields);
    },
    methods: {
      ...mapMutations({
        toggleTermsStatus: 'termsAgreement/toggleTermsStatus',
      }),

      toggleLabelUp(data) {

        if (data === 'email' && (this.userData.email === '')) {
          this.toggleEmail = true;
          this.LabelColorEmail = true;
        }

        if (data === 'password' && (this.userData.password === '')) {
          this.togglePassword = true;
          this.LabelColorPassword = true;
        }

        if (data === 'confirm_password' && (this.userData.confirm_password === '')) {
          this.togglePasswordConfirm = true;
          this.LabelColorPasswordConfirm = true;
        }

        if (data === 'first_name' && (this.userData.first_name === '')) {
          this.toggleName = true;
          this.LabelColorName = true;
        }

        if (data === 'last_name' && (this.userData.last_name === '')) {
          this.toggleLastName = true;
          this.LabelColorLastName = true;
        }
      },

      toggleLabelDown(data) {
        if (data === 'email' && (this.userData.email === '')) {
          this.errorLabelEmail = true;
          this.toggleEmail = false;
          this.LabelColorEmail = false;
          this.errorLine.email = true;
          this.checkField.email = true;
        }

        if (data === 'password' && (this.userData.password === '')) {
          this.errorLabelPassword = true;
          this.togglePassword = false;
          this.LabelColorPassword = false;
          this.errorLine.password = true;
          this.checkField.password = true;
        }

        if (data === 'confirm_password' && (this.userData.confirm_password === '')) {
          this.errorLabelPasswordConfirm = true;
          this.togglePasswordConfirm = false;
          this.LabelColorPasswordConfirm = false;
          this.errorLine.confirm_password = true;
          this.checkField.confirm_password = true;
        }

        if (data === 'first_name' && (this.userData.first_name === '')) {
          this.toggleName = false;
          this.LabelColorName = false;
          this.LabelColorLastName = false;
        }

        if (data === 'last_name' && (this.userData.last_name === '')) {
          this.toggleLastName = false;
          this.LabelColorLastName = false;
          this.LabelColorName = false;
        }
      },

      checkFields(data) {
        if (data === 'email' && (this.userData.email !== '')) {
          this.LabelColorEmail = true;
          this.errorLine.email = false;
          this.checkField.email = false;
        }

        if (data === 'password' && (this.userData.password !== '')) {
          this.LabelColorPassword = true;
          this.errorLine.password = false;
          this.checkField.password = false;

        }

        if (data === 'confirm_password' && (this.userData.confirm_password !== '')) {
          this.LabelColorPasswordConfirm = true;
          this.checkField.confirm_password = false;
          this.errorLine.confirm_password = false;
        }

        if (data === 'first_name' && (this.userData.first_name !== '')) {
          this.LabelColorName = true;
          this.errors.first_name = false;
        }

        if (data === 'last_name' && (this.userData.last_name !== '')) {
          this.LabelColorLastName = true;
          this.errors.last_name = false;
        }
      },

      loginFromGoogle() {
        if (!this.isTermsAccepted) {
          this.clickTermOfUse();

          return;
        }
        ;

        Auth.saveCurrentPath(location.pathname.replace('/portal', ''));

        // localStore.set('show-account-after-sso-login', +!this.skipAccountPopupAfterLogin);

        location.href = 'https://' + location.host + '/google/login/';
      },
      acceptTermsHandler() {
        this.toggleTermsStatus();

        this.isChecked = this.getIsTermsAccepted;
      },
      clickTermOfUse() {
        this.$bus.$emit('toggleRegisterVisibility', {
          hide: true,
        });

        this.$bus.$emit('openTermsOfUseDialog', {
          isAcceptHidden: false,
          callFromFooter: false,
        });
      },
      onShowHidePassword() {
        this.isShowPassword = !this.isShowPassword;
      },
      onShowHideRepeatPassword() {
        this.isShowRepeatPassword = !this.isShowRepeatPassword;
      },
      validate(field) {
        this.signUpShape
          .validateAt(field, this.userData)
          .then(() => {
            this.errors[field] = false;
            this.message = {};
          })
          .catch(err => {
            /* Dont delete*/
          });
      },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      translateVal(val) {
        return this.fix18n.t(val);
      },

      close() {
        this.$emit('update:isActive', false);
      },
      async submitForm() {
        this.message = {};
        const status = process.env.VUE_APP_CUSTOMER === 'nine' ? 'error' : 'warning';
        try {
          const res = await this.signUpShape.validate(this.userData);
        } catch (err) {
          this.errors[err.path] = true;
          const [message] = err.errors;
          this.showMessage({
            status,
            text: message,
          });
        }

        const valid = await this.signUpShape.isValid(this.userData);
        if (!valid) return;
        let authData = {
          email: this.userData.email,
          password: this.userData.password,
        };
        if (this.userData.coupon_code) {
          authData.coupon_code = this.userData.coupon_code;
        }
        if (this.isActiveFeature('register_form_extra_fields')) {
          authData = {
            ...authData,
            first_name: this.userData.first_name,
            last_name: this.userData.last_name,
            phone: this.userData.phone,
            is_terms_accepted: this.isTermsAccepted,
          };
        }
        Auth.register(authData)
          .then((response) => {
            this.isRegistered = true;
            this.$stats.send('registration');
            this.$bus.$emit('toggleAuthPopup', {
              component: 'success-register',
              force: true,
              forceCloseBtn: true,
              message: this.$i18n.t(
                `REGISTER_FORM.SUCCESSFULLY_REGISTERED.${process.env.VUE_APP_CUSTOMER}`,
              ),
            });
            this.userData = {
              first_name: '',
              last_name: '',
              phone: '',
              email: '',
              password: '',
              confirm_password: '',
              coupon_code: null,
            };
            if (isActiveFeature('webview_feature')) {
              setTimeout(() => {
                location.href = 'https://callback_closeme.html/';
              }, 6000);
            }
          })
          .catch((error) => {
            console.dir(error);
            if (error?.meta?.netCode === 429) {
              this.$bus.$emit('showOverloadPopup');
              this.close();
              return;
            }
            this.errors[error.meta.errorField] = true;
            this.showMessage({
              status: 'error',
              text: error.message,
            });
          });
      },
      showMessage(messageData) {
        this.message = messageData;
      },

      fbLogin() {
        const path = location.pathname + location.search;

        Auth.saveCurrentPath(path.replace('/portal', ''));

        // localStore.set('show-account-after-sso-login', +!this.skipAccountPopupAfterLogin);

        location.href = 'https://' + location.host + '/facebook/login/';
      },
    },
  };
</script>

<style lang="scss">
  .hidden {
    opacity: 0;
  }

  .auth-pass {
    position: relative;
  }

  .show-password-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);

    &:hover, &:focus, &:active {
      outline: none;
      border: none;
    }

    svg {
      color: #333953;
    }
  }

  .align-button-login {
    max-width: 230px !important;
    text-align: center !important;
  }

  .terms-wrapper {
    position: relative;
    color: black;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    margin-top: 17px;

    .checkbox-wrapper {
      position: absolute;
      top: -15px;

      .checkbox-hover {
        label {
          cursor: pointer;
        }
      }
    }

    .agreement-links-wrapper {
      margin-left: 23px;
      margin-bottom: 20px;
    }

    &-link {
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid $seller-dark-blue;
      }
    }

    &-link {
      color: $main-color !important;
    }
  }

  .social-login-title {
    color: rgba(127, 128, 149, 1)
  }

  .divider {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    span {
      opacity: 1;
      color: rgba(127, 128, 149, 1);
      font-size: 12px;
      line-height: 16px;
    }

    ::before {
      content: '';
      position: absolute;
      left: 25px;
      top: 9px;
      width: 102px;
      height: 1px;
      opacity: 1;
      background-color: rgba(217, 224, 238, 1);
    }

    ::after {
      content: '';
      position: absolute;
      right: 25px;
      top: 9px;
      width: 102px;
      height: 1px;
      opacity: 1;
      background-color: rgba(217, 224, 238, 1);
    }
  }

  .icon-eye {
    width: 24px;
    height: 18px
  }
</style>
